import React, { useEffect } from 'react'
import Header from '../components/Header.';
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { Container, Row } from 'react-bootstrap'
import payment from "../assets/myaccount/payment.png";
import location from "../assets/myaccount/address-map-pin.png"
import signin from "../assets/myaccount/sign-in-lock.png"
import box from "../assets/myaccount/Box.png"
import tracking from "../assets/myaccount/tracking.png";
import contact from "../assets/myaccount/contact-us.png"

const MyAccount = () => {
    const navigate = useNavigate();
    const pathname = window.location.href;
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    useEffect(() => {
        if (userid) {
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::My Account</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content="user account" />
                <meta name="description" content="user account" />
                <meta name="keyword" content="user account" />
                <meta property="og:url" content={pathname} />
            </Helmet>
            <Header />
            <div className="myaccount-section mb-4 bg-white">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">user dashboard</li>
                        </ol>
                    </nav>
                    <Row className="userdashboard">
                        <h1 className="accountHeading text-center mt-4">Your Account</h1>
                        <div className="col-md-4">
                            <Link to="/my-orders">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={box} alt={box} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>My Orders</h4>
                                        <span>Track, retrun or buy things again</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={signin} alt={signin} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Login & Security</h4>
                                        <span>Edit login, name and mobile number</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/order-address">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={location} alt={location} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Your Address</h4>
                                        <span>Edit address for orders</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={payment} alt={payment} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Payment Options</h4>
                                        <span>Edit or payment method</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <div className="accountSection">
                                <div className="imgSection">
                                    <img src={contact} alt={contact} />
                                </div>
                                <div className="contentSection">
                                    <h4>Contact Us</h4>
                                    <span className="callnow">Call Now <Link to="tel:9319728256">+91-9319728256</Link></span><br />
                                    <span>Email: <Link to="mail:support@satkartar.co.in">support@satkartar.co.in</Link></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user-dashboard">
                                <div className="accountSection">
                                    <div className="imgSection">
                                        <img src={tracking} alt={tracking} />
                                    </div>
                                    <div className="contentSection">
                                        <h4>Track Your Order</h4>
                                        <span>Check the status of your order</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default MyAccount