import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer';
import Header from '../components/Header.';
import { useCart } from 'react-use-cart';
import { getSingleDataApi } from '../utils/api';
import { SlCalender } from 'react-icons/sl';
import moment from 'moment';
import { numberFormat } from '../utils/numberFormate';
import { FaUserAlt } from 'react-icons/fa';
import { MdLocationPin, MdPayment, MdWifiCalling3 } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import noorder from "../assets/no-order.jpg"
import { baseUrl } from '../utils/baseUrl';

const MyOrders = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const { addItem } = useCart();
    const buyItNow = (productd) => {
        addItem(productd);
        navigate("/checkout");
    }
    const [myorder, setMyOrder] = useState([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    useEffect(() => {
        if (userid) {
            getMyOrder(userid);
        }
        else {
            navigate("/login");
        }
    }, [userid]);

    const getMyOrder = (userid) => {
        getSingleDataApi(`get-my-order/${userid}`).then((res) => {
            if (res.data.status === 1) {
                setMyOrder(...[res.data.myorder])
            }
        })
    }

    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Orders</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="myorder-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item" to="/">
                                <Link to="/my-account" role="button" tabIndex="1">My Account</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody mt-4">
                        <Col>
                            {myorder != "" ?
                                <div className="myOrderDetails">
                                    <h2>MY ORDERS</h2>
                                    {myorder?.map((myorder, index) => (
                                        <div className="orderCard mb-2" key={index}>
                                            <div className="orderDetails">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="orderPacedDetails">
                                                            <div className="orderPlaced">
                                                                <span className="orderType">Order Placed</span>
                                                                <span className="orderTypeDetails"><SlCalender />{moment(myorder?.created_at).format("MMMM DD YYYY")}</span>
                                                            </div>
                                                            <div className="orderTotal">
                                                                <span className="orderType">Total</span>
                                                                <span className="orderTypeDetails">{numberFormat(myorder?.product_price)} x {myorder?.product_qty} = {numberFormat(myorder?.product_sub_total)}</span>
                                                            </div>
                                                            <div className="orderShipTo">
                                                                <span className="orderType">SHIP TO</span>
                                                                <span className="orderTypeDetails orderShipName"><FaUserAlt />{myorder?.order_name}</span>
                                                            </div>
                                                            <div className="orderPaymentMethod">
                                                                <span className="orderType">Payment Method</span>
                                                                <span className="orderPaymentDetails orderShipName"><MdPayment />{myorder?.payment_type}</span>
                                                            </div>
                                                            <div className="orderPaymentMethod">
                                                                <span className="orderType">Size</span>
                                                                <span className="orderPaymentDetails orderShipName">{myorder?.product_size}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="orderId">
                                                            <span>ORDER ID#: {myorder?.orderid}</span>
                                                            <br />
                                                            <Link target="_blank" to={`/order-invoice/${myorder?.orderid}`}>View order details</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="shipProductDetails">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="productImg">
                                                            <img src={baseUrl(`product/${myorder?.product_image}`)} alt={myorder?.product_image} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="productNameBtn">
                                                            <Link to="/"><h2>{myorder?.product_name}</h2>
                                                                <div className="orderMobile">
                                                                    <MdWifiCalling3 />
                                                                    <span>{myorder?.order_mobile}</span>
                                                                </div>
                                                                <div className="orderAddress">
                                                                    <MdLocationPin />
                                                                    <p>{myorder?.order_address}</p>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="getProductSupport">
                                                            <Link to="tel:+91-9319728256"><BiSupport />Get Product Support</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className="orderNotFounds">
                                    <h2>MY ORDERS</h2>
                                    <div className="norderimg">
                                        <img src={noorder} alt={noorder} />
                                    </div>
                                    <Link className="continue-shoppingbtn" to="/products">Continue Shopping</Link>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default MyOrders