import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VideoPopup from './VideoPopup';
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const VideoSection = () => {
    const [videopopupShow, setVideoPopupShow] = useState(false);
    const [videoid, setVideoId] = useState("");
    const handleVideoPopup = (videoid) => {
        setVideoId(videoid)
        setVideoPopupShow(true)
    }
    const [videos, setVideo] = useState([]);
    useEffect(() => {
        getHomeVideo()
    }, []);

    const getHomeVideo = () => {
        fetchDataFromApi('get-home-video-data').then((resp) => {
            setVideo(...[resp.video])
        })
    }
    return (
        <div className="div" bg="light">
            <VideoPopup
                show={videopopupShow}
                onHide={() => setVideoPopupShow(false)}
                videoid={videoid}
            />
            <Container className="videosection desktop">
                <Carousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                    {videos?.map((videos, index) => (
                        <div className="videobody" key={index} onClick={() => handleVideoPopup(videos?.id)}>
                            <video width={256} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                                <source src={baseUrl(`video/${videos?.video_3sec}`)} type="video/mp4" />
                                <source src={baseUrl(`video/${videos?.video_3sec}`)} type="video/ogg" />
                            </video>
                        </div>
                    ))}
                </Carousel>
            </Container>
            <Container className="mobile">
                <div className="mvideosection">
                    {videos?.map((videos, index) => (
                        <div className="videobody" key={index} onClick={() => handleVideoPopup(videos?.id)}>
                            <video width={200} preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                                <source src={baseUrl(`video/${videos?.video_3sec}`)} type="video/mp4" />
                                <source src={baseUrl(`video/${videos?.video_3sec}`)} type="video/ogg" />
                            </video>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    )
}

export default VideoSection