import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Product from "./pages/Product";
import Ingredients from "./pages/Ingredients";
import ShopByConcern from "./pages/ShopByConcern";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundCancellationPolicy from "./pages/RefundCancellationPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import Testimonial from "./pages/Testimonial";
import Faqs from "./pages/Faqs";
import ProductDetail from "./pages/ProductDetail";
import CheckoutLogin from "./pages/CheckoutLogin";
import DeliveryAddress from "./pages/DeliveryAddress";
import OrderSummary from "./pages/OrderSummary";
import PaymentOption from "./pages/PaymentOption";
import 'react-loading-skeleton/dist/skeleton.css'
import { CartProvider } from 'react-use-cart';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyAccount from "./pages/MyAccount";
import UserDashboard from "./pages/UserDashboard";
import ThankYou from "./pages/ThankYou";
import MyOrders from "./pages/MyOrders";
import OrderAddress from "./pages/OrderAddress";
import CategoryProduct from "./pages/CategoryProduct";
import SearchProduct from "./pages/SearchProduct";

function App() {
  return (
    <>
      <CartProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Product />} />
            <Route path="/products/:slug" element={<ProductDetail />} />
            <Route path="/ingredients" element={<Ingredients />} />
            <Route path="/shop-by-concern" element={<ShopByConcern />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/term-and-condition" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundCancellationPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/checkout-login" element={<CheckoutLogin />} />
            <Route path="/delivery-address" element={<DeliveryAddress />} />
            <Route path="/order-summary" element={<OrderSummary />} />
            <Route path="/payment-options" element={<PaymentOption />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/my-orders" element={<MyOrders />} />
            <Route path="/order-address" element={<OrderAddress />} />
            <Route path="/category/:slug" element={<CategoryProduct />} />
            <Route path="/search/*" element={<SearchProduct />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer position="bottom-center" />
      </CartProvider>
    </>
  );
}

export default App;
