import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { getCategoryProductApi, getMultitpleDataApi } from '../utils/api'
import { numberFormat } from '../utils/numberFormate'
import { baseUrl } from '../utils/baseUrl'
import { useCart } from 'react-use-cart'
import LoginPopup from '../components/LoginPopup'
import { toast } from 'react-toastify';


const CategoryProduct = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const { slug } = useParams();
    const pathname = window.location.href;
    const [categorytitle, setCategoryTitle] = useState([]);
    const [product, setProduct] = useState([]);
    const [loginPopupShow, setLoginPopupShow] = useState(false);


    useEffect(() => {
        getCategoryProduct(slug)
    }, [slug])

    const getCategoryProduct = (slug) => {
        getCategoryProductApi(`get-category-product/${slug}`).then((res) => {
            if (res?.data.status === 1) {
                setProduct(...[res?.data.product]);
                setCategoryTitle(...[res?.data.category]);
            }
            else {
                toast.warning("Check your connection!!");
            }
        })
    }
    const handleAddToCart = (productid, pname, psku, pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image: pimage,
                };
                addItem(product);
                toast.success("Product has been added in your cart!");
            });
        }
    }
    const handleBuyNow = (productid, pname, psku, pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image: pimage,
                };
                addItem(product);
                if (localStorage.getItem('userdata')) {
                    navigate('/delivery-address');
                }
                else {
                    setLoginPopupShow(true);
                    localStorage.setItem("userpopup", "userpopup")
                }
            });
        }
    }
    return (
        <div className="products themebackgroundcolor">
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{categorytitle?.category_name}</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="productseaction mt-2 mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item" to="/">
                                <Link to="/shop-by-concern" role="button" tabIndex="1">Shop by concern</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{categorytitle?.category_name}</li>
                        </ol>
                    </nav>
                    <Row>
                        <Col md={12} sm={12}>
                            <Row>
                                <h4 className="text-center">{categorytitle?.category_name}</h4>
                                {product.length > 0 ? product.map((product, index) => (
                                    <Col md={3} sm={6} key={index}>
                                        <Card className="product-body">
                                            <Link to={`/products/${product?.product_slug_url}`}>
                                                <Card.Img variant="top" src={baseUrl(`product/${product?.image}`)} alt={product?.image} />
                                            </Link>
                                            <Card.Body>
                                                <Link to={`/products/${product?.product_slug_url}`}>
                                                    <Card.Title>{product?.page_title}</Card.Title>
                                                </Link>
                                                <Card.Text>
                                                    <div className="offersection">
                                                        <div className="priceSection">
                                                            <span className="price">{numberFormat(product?.price)}</span>
                                                            <del className="cutprice">{numberFormat(product?.product_cut_price)}</del>
                                                        </div>
                                                        <div className="offer">
                                                            <span>{product?.product_save}</span>
                                                        </div>
                                                    </div>
                                                    <div className="ratingSection">
                                                        <span className="rating">
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStarHalfAlt />
                                                        </span>
                                                        <span className="totalreview">{Math?.floor(Math.random() * 5000)} reviews</span>
                                                    </div>
                                                </Card.Text>
                                                <div className="buttonSection">
                                                    <Button onClick={() => handleAddToCart(product?.id, product?.name, product?.product_sku, product?.image)} className="addtocart">Add To Cart</Button>
                                                    <Button onClick={() => handleBuyNow(product?.id, product?.name, product?.product_sku, product?.image)} className="buyitnow">Buy Now</Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                                    :
                                    <div className="text-center"><h1>Data Not founds</h1></div>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default CategoryProduct