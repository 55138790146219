import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import SCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 8
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const ShopByConcern = () => {
    const [cattitle, setCatTitle] = useState([])
    const [category, setCategory] = useState([])

    useEffect(() => {
        getCatTitle()
        getCategory()
    }, [])

    const getCatTitle = () => {
        fetchDataFromApi('home-category-title').then((resp) => {
            if (resp.status === 1) {
                setCatTitle(...[resp.data])
            }
        })
    }
    const getCategory = () => {
        fetchDataFromApi('home-category').then((resp) => {
            if (resp.status === 1) {
                setCategory(...[resp.data])
            }
        })
    }
    return (
        <>
            <div className="desktop">
                <Container className="bodySection shopByConcernsection">
                    <div className="descHeader text-center">
                        <h2>{cattitle?.category_heading}</h2>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{ __html: cattitle?.category_desc }}>
                            </div>
                        </div>
                    </div>
                    <SCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        {category?.map((category, index) => (
                            <Link to={`/category/${category?.category_slug_url}`} className="shopByConcern" key={index}>
                                <Image src={baseUrl(`category/${category?.category_img}`)} rounded />
                            </Link>
                        ))}
                    </SCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2>{cattitle?.category_heading}</h2>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{ __html: cattitle?.category_desc }}>
                            </div>
                        </div>
                    </div>
                    <div className="mobileshopby">
                        {category?.map((category, index) => (
                            <Link to={`/category/${category?.category_slug_url}`} className="shopByConcern" key={index}>
                                <Image src={baseUrl(`category/${category?.category_img}`)} rounded />
                            </Link>
                        ))}
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ShopByConcern