import React, { useEffect } from 'react'
import "../App.scss";
import TopBar from '../components/TopBar';
import MegaMenuBar from '../components/MegaMenuBar';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[location]);

    return (
        <div>
            <TopBar />
            <MegaMenuBar />
        </div>
    )
}

export default Header