import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { fetchDataFromApi } from '../utils/api'

const ShippingPolicy = () => {
    const [shipping, setShipping] = useState([]);
    const pathname = window.location.href;

    useEffect(() => {
        getShippingCondition();
    }, [])
    const getShippingCondition = () => {
        fetchDataFromApi("shipping-policy").then((res) => {
            if (res.status === 1) {
                setShipping(...[res.data]);
            }
        })
    };
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{shipping?.page_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={shipping?.meta_title} />
                <meta name="description" content={shipping?.meta_description} />
                <meta name="keywords" content={shipping?.meta_keyword} />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={shipping?.meta_title} />
                <meta property="og:description"
                    content={shipping?.meta_description} />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{shipping?.policy_heading}</li>
                        </ol>
                    </nav>
                    <Row className="aboutbody">
                        <h1 className="text-center">{shipping?.policy_heading}</h1>
                        <div className="col-md-12 col-sm-12">
                            <div dangerouslySetInnerHTML={{ __html: shipping?.policy_desc }}></div>
                        </div>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default ShippingPolicy