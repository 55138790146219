import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import img1 from "../../assets/topmarquee/nano1.webp"
import img2 from "../../assets/topmarquee/nano2.webp"
import img3 from "../../assets/topmarquee/nano3.webp"
import img4 from "../../assets/topmarquee/nano4.webp"
import img5 from "../../assets/topmarquee/nano5.webp"
import img6 from "../../assets/topmarquee/nano6.webp"
import { fetchDataFromApi } from '../../utils/api'
import { baseUrl } from '../../utils/baseUrl'

const TopMarquee = () => {
    const [topmarquee, setTopMarquee] = useState([])

    useEffect(() => {
        getTopMarquee()
    }, [])

    const getTopMarquee = () => {
        fetchDataFromApi('home-marquee').then((resp) => {
            setTopMarquee(...[resp.data])
        })
    }
    return (
        <div className="topmarquee">
            <div className="row">
                <marquee direction="left" behavior="scroll" loop={true}>
                    <div className="d-flex">
                        {topmarquee?.map((topmarquee, index) => (
                            <div className="marqueeBody" key={index}>
                                <Image src={baseUrl(`natural/${topmarquee?.natural_img}`)} alt={topmarquee?.natural_title}></Image>
                                <span className="text"> {topmarquee?.natural_title}</span>
                            </div>
                        ))}
                    </div>
                </marquee>
            </div>
        </div >
    )
}

export default TopMarquee