import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MdOutlineCorporateFare, MdOutlineWifiCalling3, MdOutlineMailOutline } from "react-icons/md";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaYoutube } from 'react-icons/fa';
import payment from "../assets/footer/payment-footer.png"

const Footer = () => {
    return (
        <>
            <div className="footerbackgroundcolor">
                <Container>
                    <Row>
                        <Col className="footer">
                            <div className="footerNewsletter">
                                <form method="post">
                                    <h4>Sign-up for our newsletter</h4>
                                    <p>Find out all about our latest offers, new products, the science of Ayurved and a lot more in our newsletters!</p>
                                    <input type="email" name="new_email" placeholder="Your Email Address" id="new_email" required="" />
                                    <button type="submit">SUBSCRIBE</button>
                                </form>
                            </div>
                        </Col>
                        <Col className="footer">
                            <h4>USEFULL LINKS</h4>
                            <ul className="userfulllink">
                                <li><Link to="/products">Product</Link></li>
                                <li><Link to="/">Contact Us</Link></li>
                                <li><Link to="/shop-by-concern">Shop By Concern</Link></li>
                                <li><Link to="/ingredients">Ingredients</Link></li>
                                <li><Link to="/">Blog</Link></li>
                            </ul>
                        </Col>
                        <Col className="footer">
                            <h4>INFORMATION LINKS</h4>
                            <ul className="userfulllink">
                                <li><Link to="/term-and-condition">Terms & Conditions</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/refund-policy">Refund & Cancellation Policy</Link></li>
                                <li><Link to="/shipping-policy">Shipping / Delivery Policy</Link></li>
                                <li><Link to="/testimonial">Testimonial</Link></li>
                                <li><Link to="/faqs">FAQS</Link></li>
                            </ul>
                        </Col>
                        <Col className="footer">
                            <h4>CONNECT WITH US</h4>
                            <ul className="userfulllink">
                                <li><MdOutlineCorporateFare />Sat Kartar Shopping Ltd</li>
                                <li><FaLocationDot />6th Floor, Unit Number 603, Mercantile House, K.G. Marg, New Delhi, Delhi, 110001</li>
                                <li><MdOutlineWifiCalling3 />+91-11-40550741</li>
                                <li><MdOutlineMailOutline />support@satkartar.in</li>
                            </ul>
                            <ul className="socialmedialink">
                                <li><Link to="/"><FaFacebook /></Link></li>
                                <li><Link to="/"><FaXTwitter /></Link></li>
                                <li><Link to="/"><FaLinkedin /></Link></li>
                                <li><Link to="/"><FaYoutube /></Link></li>
                                <li><Link to="/"><FaPinterest /></Link></li>
                                <li><Link to="/"><FaInstagram /></Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footerBottom">
                <Container>
                    <Row>
                        <Col>
                            <address>Sat Kartar Shopping Ltd © 2024. All Rights Reserved</address>
                        </Col>
                        <Col>
                            <Image src={payment}></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer