import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { fetchDataFromApi } from '../utils/api'
import { baseUrl } from '../utils/baseUrl'

const ShopByConcern = () => {
    const [cattitle, setCatTitle] = useState([])
    const [category, setCategory] = useState([])
    const pathname = window.location.href;

    useEffect(() => {
        getCatTitle()
        getCategory()
    }, [])

    const getCatTitle = () => {
        fetchDataFromApi('home-category-title').then((resp) => {
            if (resp.status === 1) {
                setCatTitle(...[resp.data])
            }
        })
    }
    const getCategory = () => {
        fetchDataFromApi('home-category').then((resp) => {
            if (resp.status === 1) {
                setCategory(...[resp.data])
            }
        })
    }
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Shop By Concern</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">ingredients</li>
                        </ol>
                    </nav>
                    <Row className="shopByConcernbody">
                        <h1 className="text-center">{cattitle?.category_heading}</h1>
                        <div className="text-center" dangerouslySetInnerHTML={{ __html: cattitle?.category_desc }}>
                        </div>
                        {category?.map((category, index) => (
                            <Link to={`/category/${category?.category_slug_url}`} className="shopByConcern" key={index}>
                                <Image src={baseUrl(`category/${category?.category_img}`)} rounded />
                            </Link>
                        ))}
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default ShopByConcern