import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Step, Stepper } from 'react-form-stepper'
import Footer from '../components/Footer'
import { useCart } from 'react-use-cart'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { addPaymentDataAPIFromPhonepay, fetchDataFromApi, getPaymentStatusApi, placeOrderAPi } from '../utils/api'
import loadinggif from "../assets/loading-gif.gif";
import { numberFormat } from '../utils/numberFormate'

const PaymentOption = () => {
    const pathname = window.location.href;
    const [paymentmethod, setPaymentMethod] = useState("");
    const orderid = localStorage.getItem("orderid");
    const [isLoading, setLoading] = useState(false);
    const lproductid = localStorage.getItem("lproductid");
    const [paystatus, setPayStatus] = useState([]);
    const [orderc, setOrderc] = useState([]);
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    const {
        items,
        cartTotal,
    } = useCart();

    useEffect(() => {
        getPaymentStatus(lproductid)
        getOrderDetails(orderid)
    }, []);
    const getPaymentStatus = (lproductid) => {
        getPaymentStatusApi(`get-payment-type-status/${lproductid}`).then((res) => {
            if (res.data.status === 1) {
                setPayStatus(...[res.data.paystatus]);
            }
        })
    }
    const getOrderDetails = () => {
        fetchDataFromApi(`get-confirm-order-details/${orderid}`).then((res) => {
            if (res.status === 1) {
                setOrderc(...[res.orderdata]);
            }
        });
    }
    const handlePaymentOptions = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (paymentmethod === "COD") {
            localStorage.setItem("paymentmethod", "COD");
            const datas = { 'paymentmethod': paymentmethod, 'orderid': orderid };
            placeOrderAPi("update-place-orders", datas).then((res) => {
                if (res.data.status === 1) {
                    setLoading(false);
                    window.open('/thank-you', "_self");
                }
            })
        }
        else if (paymentmethod === "PAY") {
            const datas = { 'orderid': orderid };
            addPaymentDataAPIFromPhonepay('pay-now-to-phonepay', datas).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("transactionid", res.data.Trasid);
                    const redirect = res.data.payUrl;
                    window.location.replace(redirect);
                }
            })
        }
        else {
            setLoading(false);
            toast.error("paymentmethod");
        }
    }

    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Payment Option</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">payment-option</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={3}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <Row className="mt-4">
                        <Col md={8}>
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Payment Options</h4>
                                </div>
                                <div className="account-content">
                                    <form method="post" onSubmit={handlePaymentOptions}>
                                        <div className="row">
                                            {(() => {
                                                if (paystatus?.pay_status == 1 && paystatus?.cod_status == 1) {
                                                    return (
                                                        <>
                                                            <div className="col-md-6 mb-3 paymentType">
                                                                <div className="paymentmethod">
                                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"PAY"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                    <label htmlFor="paymentmethod">PAY NOW</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-3 paymentType">
                                                                <div className="paymentmethod">
                                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"COD"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                    <label htmlFor="paymentmethod">COD(Cash On Delivery)</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else if (paystatus?.pay_status == 1 && paystatus?.cod_status == 0) {
                                                    return (
                                                        <>
                                                            <div className="col-md-6 mb-3 paymentType">
                                                                <div className="paymentmethod">
                                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"PAY"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                    <label htmlFor="paymentmethod">PAY NOW</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else if (paystatus?.pay_status == 0 && paystatus?.cod_status == 1) {
                                                    return (
                                                        <div className="col-md-6 mb-3 paymentType">
                                                            <div className="paymentmethod">
                                                                <input type="radio" id="paymentmethod" name="paymentmethod" value={"COD"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                <label htmlFor="paymentmethod">COD(Cash On Delivery)</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <>
                                                            <div className="col-md-6 mb-3 paymentType">
                                                                <div className="paymentmethod">
                                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"PAY"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                    <label htmlFor="paymentmethod">PAY NOW</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-3 paymentType">
                                                                <div className="paymentmethod">
                                                                    <input type="radio" id="paymentmethod" name="paymentmethod" value={"COD"} required={true} onChange={e => setPaymentMethod(e.target.value)} />
                                                                    <label htmlFor="paymentmethod">COD(Cash On Delivery)</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })()}
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    {isLoading ?
                                                        <button disabled={true} type="submit" className="btncheckcont">PROCESSING... <img src={loadinggif} width={20} /></button>
                                                        :
                                                        <button type="submit" className="btncheckcont">PLACE ORDER</button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Price Details</h4>
                                </div>
                                <div className="price-summary">
                                    <div className="price-summary-details">
                                        <span>Sub Total</span>
                                        <span>{numberFormat(cartTotal)}</span>
                                    </div>
                                    <div className="price-summary-details">
                                        <span>Discount</span>
                                        <span className="priceCharge">
                                            <b>0.00</b>
                                        </span>
                                    </div>
                                    <div className="price-summary-details">
                                        <span>Delivery Charges</span>
                                        <span className="priceCharge">
                                            <b>Free</b>
                                        </span>
                                    </div>
                                    <div className="price-summary-details total-amount-details">
                                        <span>
                                            <b>Amount Payable</b>
                                        </span>
                                        <span>
                                            <b>{numberFormat(cartTotal)}</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentOption