import React from 'react'
import { Container, Image } from 'react-bootstrap'
import stickproduct from "../../assets/counter/stickproduct.webp"
import CountUp from 'react-countup';

const ProductCounter = () => {
    return (
        <div className="secondbackgroundcolor">
            <div className="desktop">
                <Container className="productCounter">
                    <div className="descHeader text-center">
                        <h2>Your Gut Microbiome Is At The Core Of Your Well Being.</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <div className="counter-block">
                        <div className="counterLeft">
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                        </div>
                        <div className="productCenter">
                            <Image src={stickproduct} />
                        </div>
                        <div className="counterRight">
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container className="productCounter">
                    <div className="descHeader text-center">
                        <h2>Your Gut Microbiome Is At The Core Of Your Well Being.</h2>
                        <div className="desc">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, reiciendis. Magni corrupti consequatur ratione? Quibusdam voluptas nesciunt maiores, minima asperiores reprehenderit expedita consequatur perspiciatis sapiente sequi illum neque provident velit. Explore our collection of top selling Ayurvedic products and alternative medicines in India.</p>
                        </div>
                    </div>
                    <div className="counter-block">
                        <div className="counterLeft">
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                            <div className="microbiome-block">
                                <div className="loader">
                                    <span className="count counter-loaded">
                                        <CountUp
                                            end={1000}
                                            duration={100}
                                        />
                                    </span>
                                    <span className="percen">%</span>
                                </div>
                                <h4>Your Health</h4>
                                <p>Up to 90% all disease can be traced back in someway to the gut and the health of the microbiome</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ProductCounter