import React, { useEffect, useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import MCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const MarketPlaceMarquee = () => {
    const [market, setMarket] = useState([]);

    useEffect(() => {
        getMarketPlace()
    }, [])

    const getMarketPlace = () => {
        fetchDataFromApi('home-market').then((resp) => {
            setMarket(...[resp.data])
        })
    }
    return (
        <div className="firstbackgroundcolor">
            <div className="">
                <Container>
                    <div className="descHeader text-center">
                        <h2>EASY SHOPPING PLATFORM</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <MCarousel autoPlay={true} responsive={responsive} infinite={true} arrows={false} showDots={true}>
                        {market?.map((market, index) => (
                            <div className="mimg" key={index}>
                                <Image src={baseUrl(`marketplace/${market?.market_img}`)} alt={market?.market_img}></Image>
                            </div>
                        ))}
                    </MCarousel>
                </Container>
            </div>
        </div>
    )
}

export default MarketPlaceMarquee