import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/logo.webp";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaShoppingBag, FaShoppingCart } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaMicrophone } from "react-icons/fa";
import { FaRegTrashCan } from 'react-icons/fa6';
import LoginPopup from './LoginPopup';
import { useCart } from 'react-use-cart';
import { numberFormat } from '../utils/numberFormate';
import { baseUrl } from '../utils/baseUrl';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { RxCross2 } from 'react-icons/rx';
import Modal from 'react-bootstrap/Modal';

const initialState = {
    search_name: '',
}
const MegaMenuBar = () => {
    const navigate = useNavigate();
    const clickMobileMegaMenu = (url) => {
        navigate(url);
    }
    const [searchValues, setSearchValues] = useState(initialState);
    const handleInputs = e => {
        setSearchValues({
            ...searchValues,
            [e.target.name]: e.target.value,
        })
    }
    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        navigate(`/search?q=${searchValues.search_name}`);
    }
    const {
        items,
        totalUniqueItems,
        updateItemQuantity,
        cartTotal,
        removeItem
    } = useCart();

    const removeItems = (id) => {
        removeItem(id);
    };
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    const [cartshow, setCartShow] = useState(false);
    const handleCartClose = () => setCartShow(false);
    const handleCartShow = () => setCartShow(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [megashow, setMegaShow] = useState("");
    const [megamobileshow, setMegaMobileShow] = useState("");
    const [lastScrolly, setLastScrolly] = useState(0);

    const controlNavbar = () => {
        if (window.scrollY > 150) {
            if (window.screenY > lastScrolly) {
                setMegaShow("hide");
            }
            else {
                setMegaShow("show");
            }
        }
        else {
            setMegaShow();
        }
        setLastScrolly(window.scrollY);
    }
    const controlMobileNavbar = () => {
        if (window.scrollY > 90) {
            if (window.screenY > lastScrolly) {
                setMegaMobileShow("hide");
            }
            else {
                setMegaMobileShow("show");
            }
        }
        else {
            setMegaMobileShow();
        }
        setLastScrolly(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        window.addEventListener("scroll", controlMobileNavbar);
        return () => {
            window.addEventListener("scroll", controlNavbar);
            window.addEventListener("scroll", controlMobileNavbar);
        }
    }, [lastScrolly]);
    const clickToDeskProceedCheckout = (checkurl) => {
        navigate(checkurl);
    }
    const clickToDeskProceedCheckoutLogin = () => {
        setCartShow(false)
        setLoginPopupShow(true);
        localStorage.setItem("userpopup", "userpopup")
    }
    const {
        transcript,
        resetTranscript
    } = useSpeechRecognition();

    const ref = useRef(null);
    const handleStartListining = () => {
        setVoicePopup(true)
        ref.current.value = '';
        setSearchValues(false);
        SpeechRecognition.startListening()
    }
    if (transcript) {
        setTimeout(() => {
            navigate(`/search?q=${transcript}`);
            resetTranscript();
            setVoicePopup(false)
        }, 2000);
        clearTimeout();
    }
    const handleRemoveSearch = () => {
        ref.current.value = '';
        setSearchValues(false);
        resetTranscript();
    }
    const [voicePopup, setVoicePopup] = useState(false);
    const voicePopupClose = () => setVoicePopup(false);

    return (
        <>
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <Modal className="voicepopupbody" aria-labelledby="contained-modal-title-vcenter" centered={true} show={voicePopup} onHide={voicePopupClose} animation={false}>
                <Modal.Body>
                    {transcript ?
                        <p>{transcript}</p>
                        :
                        <div className="voiceBody">
                            <span className="voiceIcons">
                                <FaMicrophone />
                            </span>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <div className={`desktop ${megashow}`}>
                <Navbar className="headerSearchBar themebackgroundcolor">
                    <Container>
                        <div className="headerLogo">
                            <Link to="/">
                                <img src={logo} alt={logo} />
                            </Link>
                        </div>
                        <div className="headerSearch">
                            <Nav className="me-auto">
                                <form method="GET" name="site-search" role="search" onSubmit={handleSearchSubmit}>
                                    <div className="searchBox">
                                        <input onChange={handleInputs} type="text" name="search_name" placeholder="Search for Medicines and Health Products" />
                                        <div className="searchtbn">
                                            {searchValues ?
                                                <button type="submit" className="searchiconssubmit">
                                                    <IoSearchOutline className="header_search_icon" />
                                                </button> :
                                                <button disabled type="submit" className="searchiconssubmit">
                                                    <IoSearchOutline className="header_search_icon" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </Nav>
                        </div>
                        <div className="headerCart">
                            <div className="topCartCounter">
                                <FaShoppingBag className="cartIcons" onClick={handleCartShow} />
                                <span className="cartCounter">{totalUniqueItems}</span>
                            </div>
                            <div className="yourCart">
                                <span className="textCart">TOTAL PRICE</span>< br />
                                <span className="totalPriceCart">{numberFormat(cartTotal)}</span>
                            </div>
                        </div>
                    </Container>
                </Navbar>
                <Navbar bg="white" data-bs-theme="dark" className="navbarsection">
                    <Container>
                        <Nav className="me-auto desktopnavbar">
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/">HOME</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/products')} to="/products">AYURVEDA PRODUCTS</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/ingredients')} to="/ingredients">INGREDIENTS</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/shop-by-concern')} to="/shop-by-concern">SHOP BY CONCERN</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">ABOUT US</NavLink>
                            <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">BLOG</NavLink>
                        </Nav>
                    </Container>
                </Navbar>
            </div>
            <div className={`mobile ${megamobileshow}`}>
                <Navbar bg="white" data-bs-theme="light" className="mobileHeader">
                    <Container>
                        <div className="mobileMenuIcons">
                            <IoMdMenu onClick={handleShow} />
                        </div>
                        <div className="mobileheaderLogo">
                            <Link to="/">
                                <img src={logo} alt={logo} />
                            </Link>
                        </div>
                        <div className="mobileheaderCart">
                            <div className="topCartCounter">
                                <FaShoppingBag className="cartIcons" onClick={handleCartShow} />
                                <span className="cartCounter">{totalUniqueItems}</span>
                            </div>
                        </div>
                    </Container>
                    <Container>
                        <div className="mobileHeaderSearch">
                            <form method="GET" name="site-search" role="search" onSubmit={handleSearchSubmit}>
                                <div className="searchBox">
                                    <input ref={ref} onChange={handleInputs} type="text" name="search_name" placeholder="Search for Medicines and Health Products" />
                                    {(() => {
                                        if (searchValues.search_name) {
                                            return (
                                                <span onClick={handleRemoveSearch} className="searchiconssubmit btnenable 1">
                                                    <RxCross2 />
                                                </span>
                                            )
                                        }
                                        else if (transcript) {
                                            return (
                                                <span onClick={handleRemoveSearch} className="searchiconssubmit btnenable 2">
                                                    <RxCross2 />
                                                </span>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="searchtbn" onClick={handleStartListining}>
                                                    <FaMicrophone />
                                                </div>
                                            )
                                        }
                                    })()}

                                </div>
                            </form>
                        </div>
                    </Container>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <div className="mobileheaderLogo">
                                    <Link to="/">
                                        <img src={logo} alt={logo} />
                                    </Link>
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="me-auto mobilenavbar">
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/')} to="/">HOME</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/products')} to="/products">AYURVEDA PRODUCTS</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/ingredients')} to="/ingredients">INGREDIENTS</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/shop-by-concern')} to="/shop-by-concern">SHOP BY CONCERN</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">ABOUT US</NavLink>
                                <NavLink activeclassname="active" onClick={() => clickMobileMegaMenu('/about-us')} to="/about-us">BLOG</NavLink>
                                <span className="mobilelogin" onClick={() => setLoginPopupShow(true)} >Login</span>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas show={cartshow} onHide={handleCartClose} placement="end" name="end" backdrop="static" className="popupCart">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <FaShoppingCart />
                                <span className="totalitem">total item (<span>{totalUniqueItems}</span>)</span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="cartItems">
                                <ul>
                                    {items?.map((item, index) => (
                                        <li className="cartList" key={index}>
                                            <div className="cartBody">
                                                <div class="cart-media">
                                                    <img src={baseUrl(`product/${item?.image}`)} />
                                                </div>
                                                <div class="cart-info-group">
                                                    <div class="cart-info">
                                                        <h6>{item.name}</h6>
                                                        <small className="cartsize"><b>Size:- {item.size}</b></small>
                                                        <p>Price - {numberFormat(item.price)}</p>
                                                    </div>
                                                    <div class="cart-action-group">
                                                        <div class="product-action">
                                                            <button class="action-minus deskminus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                                <FaMinus />
                                                            </button>
                                                            <input class="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" value={item.quantity} pattern="[0-9]*" />
                                                            <button class="action-plus deskplus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                        <h6>{numberFormat(item.itemTotal)}</h6>
                                                    </div>
                                                </div>
                                                <div class="cartTrash">
                                                    <button class="cart-delete" onClick={() => removeItems(item.id)}>
                                                        <FaRegTrashCan className="cartItemTrash" />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div class="cart-footer">
                                    {localStorage.getItem('userdata') ?
                                        <a href="javascript:void(0)" class="cart-checkout-btn" onClick={() => clickToDeskProceedCheckout('/delivery-address')}>
                                            <span class="checkout-label">Proceed to Checkout</span>&nbsp;<span class="checkout-price">{numberFormat(cartTotal)}</span>
                                        </a>
                                        :
                                        <a href="javascript:void(0)" class="cart-checkout-btn" onClick={() => clickToDeskProceedCheckoutLogin()}>
                                            <span class="checkout-label">Proceed to Checkout</span>&nbsp;<span class="checkout-price">{numberFormat(cartTotal)}</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Navbar>
            </div>
        </>
    )
}

export default MegaMenuBar