import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import { Col, Container, Row } from 'react-bootstrap'
import { Step, Stepper } from 'react-form-stepper'
import { Link, useNavigate } from 'react-router-dom'
import { FaMinus, FaMobileAlt, FaPlus, FaUserAlt } from 'react-icons/fa'
import { FaLocationDot, FaRegTrashCan } from 'react-icons/fa6'
import { useCart } from 'react-use-cart'
import { getSingleDataApi } from '../utils/api'
import { baseUrl } from '../utils/baseUrl'
import { numberFormat } from '../utils/numberFormate'

const OrderSummary = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const [delivery, setDelivery] = useState([]);
    const deleiveryid = localStorage.getItem("deliveryid");
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;

    useEffect(() => {
        getDeliveryAddress();
    }, [])

    const {
        items,
        updateItemQuantity,
        cartTotal,
        isEmpty,
    } = useCart();

    const { removeItem } = useCart();
    const removeItems = (id) => {
        removeItem(id);
    };

    const getDeliveryAddress = () => {
        getSingleDataApi(`get-delivery-address/${deleiveryid}`).then((res) => {
            if (res.data.status === 1) {
                setDelivery(...[res.data.delivery])
            }
        })
    }
    const handlePaymentOption = () => {
        navigate('/payment-options');
    }
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Order Summary</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">order summary</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={2}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <div className="contentBody">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Billing Address</h4>
                                {isEmpty == "" ?
                                    <Link className="changeaddress" to="/delivery-address">Change Address</Link>
                                    :
                                    ""
                                }
                            </div>
                            <div className="account-content">
                                <div className="billingsAddress">
                                    <FaUserAlt className="userIcons" />
                                    <p>{delivery.delivery_name}</p>
                                </div>
                                <div className="billingsAddress">
                                    <FaLocationDot className="locationIcon" />
                                    <p>{delivery.delivery_addresse}, {delivery.delivery_state}, {delivery.delivery_pincode}</p>
                                </div>
                                <div className="billingsAddress">
                                    <FaMobileAlt className="locationIcon" />
                                    <b>+91-{delivery.delivery_mobile}</b>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <Row>
                                <Col md={8}>
                                    {items?.map((item, index) => (
                                        <div className="card-summary" key={index}>
                                            <div className="col-md-12">
                                                <div className="card-summary-details">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="cartImg">
                                                                <img src={baseUrl(`product/${item.image}`)} alt={item.image} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="orderSummaryDetailsPrice">
                                                                <h4>{item.name}
                                                                    <button className="btntrash" onClick={() => removeItems(item.id)}>
                                                                        <FaRegTrashCan />
                                                                    </button></h4>
                                                                <p>Size: <b>{item.size}</b></p>
                                                                <p>QTY: <b>{item.quantity}</b></p>
                                                                <p>price: {numberFormat(item.price)}</p>
                                                                <p>Sub Total: <b>{numberFormat(item.itemTotal)}</b></p>
                                                                <div className="cart-action-group mt-1">
                                                                    <div className="product-action">
                                                                        <button className="action-minus" title="Quantity Minus" onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                                                                            <FaMinus />
                                                                        </button>
                                                                        <input className="action-input" title="Quantity Number" type="text" name="quantity" id="quantity" pattern="[0-9]*" value={item.quantity} readOnly={true} />
                                                                        <button className="action-plus" title="Quantity Plus" onClick={() => updateItemQuantity(item.id, item.quantity + 1)}>
                                                                            <FaPlus />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                                <Col md={4}>
                                    <div className="account-card">
                                        <div className="account-title">
                                            <h4>Price Details</h4>
                                        </div>
                                        <div className="price-summary">
                                            <div className="price-summary-details">
                                                <span>Sub Total</span>
                                                <span>{numberFormat(cartTotal)}</span>
                                            </div>
                                            <div className="price-summary-details">
                                                <span>Discount</span>
                                                <span className="priceCharge"><b>0.00</b></span>
                                            </div>
                                            <div className="price-summary-details">
                                                <span>Delivery Charges</span>
                                                <span className="priceCharge"><b>Free</b></span>
                                            </div>
                                            <div className="price-summary-details total-amount-details">
                                                <span><b>Total Amount</b></span>
                                                <span><b>{numberFormat(cartTotal)}</b></span>
                                            </div>
                                            {isEmpty == "" ?
                                                <div className="price-summary-payment">
                                                    <button onClick={() => handlePaymentOption()}>PAYMENT OPTIONS</button>
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default OrderSummary