import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaStar } from 'react-icons/fa';
import { getMultitpleDataApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';
import { numberFormat } from '../../utils/numberFormate';
import { useCart } from 'react-use-cart';
import LoginPopup from '../LoginPopup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const VideoPopup = (props) => {
    const { addItem } = useCart();
    const videoid = props?.videoid;
    const [videodetails, setVideoDetail] = useState([]);
    const [productdetails, setProductDetail] = useState([]);
    const [variant, setVariant] = useState([]);
    const [pimage, setPImage] = useState([]);
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    const navigate = useNavigate();
    const [variantd, setVariantD] = useState(null);

    useEffect(() => {
        if (videoid) {
            getVideoDetails(videoid)
        }
    }, [videoid])

    const getVideoDetails = (videoid) => {
        getMultitpleDataApi(`get-home-video-details-data/${videoid}`).then((resp) => {
            setVideoDetail(...[resp.data?.video]);
            setProductDetail(...[resp.data?.product]);
            setVariant(...[resp.data?.variant]);
            setPImage(...[resp.data?.pimage]);
        })
    }
    const handleSelectVariant = (variantid) => {
        getMultitpleDataApi(`get-single-variant-details/${variantid}`).then((res) => {
            setVariantD(...[res?.data.variant]);
        });
    }
    const handleAddToCart = (productid, pname, psku, pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image: pimage,
                };
                addItem(product);
                toast.success("Product has been added in your cart!");
            });
        }
    }
    const handleBuyNow = (productid, pname, psku, pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image: pimage,
                };
                addItem(product);
                if (localStorage.getItem('userdata')) {
                    navigate('/delivery-address');
                }
                else {
                    setLoginPopupShow(true);
                    localStorage.setItem("userpopup", "userpopup")
                }
            });
        }
    }
    return (
        <div>
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="videoModalBody">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <Modal.Header className="mobile modalheader" closeButton></Modal.Header>
                                <video preload="none" loop={true} autoPlay={true} playsInline={true} muted={false} mediatype="video">
                                    <source src={baseUrl(`video/${videodetails?.video_30sec}`)} type="video/mp4" />
                                    <source src={baseUrl(`video/${videodetails?.video_30sec}`)} type="video/ogg" />
                                </video>
                            </Col>
                            <Col md={6}>
                                <div className="popupproductDetails">
                                    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showIndicators={true} swipeable={true} showStatus={false}>
                                        <div className="cimage">
                                            <img src={baseUrl(`product/${productdetails?.image}`)} alt={productdetails?.product_alt_name} />
                                        </div>
                                        {pimage?.map((pimage, index) => (
                                            <div className="cimage" key={index}>
                                                <img src={baseUrl(`product/${pimage?.product_image}`)} />
                                            </div>
                                        ))}
                                    </Carousel>
                                    <div className="pdetail">
                                        <h1>{productdetails?.page_title}</h1>
                                        <div className="">
                                            <div className="d-flex align-items-baseline">
                                                <small className="text-warning">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                </small>
                                                <p className="m-0 p-0">
                                                    <span className="totalrev ms-2">{Math?.floor(Math.random() * 5000)} reviews </span>
                                                </p>
                                            </div>
                                            <p>
                                                <span className="benefits_span text-dark">Trusted by 1 Million+ Happy Customers </span>
                                            </p>
                                            <p className="product__text_description">
                                                {productdetails?.short_desc}
                                            </p>
                                        </div>
                                        {(() => {
                                            if (variantd != null) {
                                                return (
                                                    <div className="productPrice">
                                                        <div className="pro-price d-flex">
                                                            <div className="price">
                                                                <span className="new-price">{numberFormat(variantd?.variant_price)}</span>
                                                                <span className="cutprice">
                                                                    <del>{numberFormat(variantd?.variant_cut_price)}</del>
                                                                </span>
                                                                <span className="p-discount">{variantd?.variant_save_amount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div className="productPrice">
                                                        <div className="pro-price d-flex">
                                                            <div className="price">
                                                                <span className="new-price">{numberFormat(productdetails?.price)}</span>
                                                                <span className="cutprice">
                                                                    <del>{numberFormat(productdetails?.product_cut_price)}</del>
                                                                </span>
                                                                <span className="p-discount">{productdetails?.product_save}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                        <Form className="mt-2">
                                            <div className="varientSesction d-flex">
                                                {variant?.map((variant, index) => (
                                                    <div className="varient" key={index}>
                                                        <input type="radio" name="varient" id={`varient${index}`} value={variant?.id} onChange={() => handleSelectVariant(variant?.id)} />
                                                        <label className="varientBody" htmlFor={`varient${index}`}>
                                                            <div className="varientDetail">
                                                                <p className="packType">{variant?.variant_pack_type}</p>
                                                                <p className="packSave">{variant?.variant_save_amount}</p>
                                                                <h4 className="packPrice">{variant?.variant_price}</h4>
                                                                <del className="cutprice">{variant?.variant_cut_price}</del>
                                                                <h6 className="totaltablet">{variant?.variant_pack_in}</h6>
                                                                <h6 className="pertablet">{variant?.variant_per_tablet}</h6>
                                                            </div>
                                                            <p className="packname">{variant?.variant_title}</p>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="buttonSection">
                                                <Button onClick={() => handleAddToCart(productdetails?.id, productdetails?.name, productdetails?.product_sku, productdetails?.image)} className="addtocart">Add To Cart</Button>
                                                <Button onClick={() => handleBuyNow(productdetails?.id, productdetails?.name, productdetails?.product_sku, productdetails?.image)} className="buyitnow">Buy It Now</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VideoPopup