import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { FaStar } from 'react-icons/fa'
import { fetchDataFromApi } from '../utils/api'
import moment from 'moment'

const Testimonial = () => {
    const pathname = window.location.href;
    const [testimonial, setTestimonial] = useState([]);
    useEffect(() => {
        getTestimonial();
    }, []);

    const getTestimonial = () => {
        fetchDataFromApi('home-testimonial').then((res) => {
            if (res.status === 1) {
                setTestimonial(...[res.data]);
            }
        })
    }
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Client Testimonials: Success Stories of our Ayurvedic Medicines</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Testimonials</li>
                        </ol>
                    </nav>
                    <Row className="testimonialbody mt-2">
                        <div className="titledesc">
                            <h1 className="text-center">What Our Customer Say About Us</h1>
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                        {testimonial?.map((testimonial, index) => (
                            <Col md={3} sm={12} key={index}>
                                <Card className="homereating">
                                    <Card.Body>
                                        <div className="namerating">
                                            <span className="rating">
                                                {testimonial?.test_rating} <FaStar />
                                            </span>
                                            <div className="user-name">
                                                <h6 content="Kiara Keshvani">{testimonial?.test_name}</h6>
                                            </div>
                                        </div>
                                        <Card.Text>
                                            "{testimonial?.test_desc}"
                                        </Card.Text>
                                        <div className="postdate">
                                            <span className="duration">
                                                {moment(testimonial?.created_at).format('Do MMMM YYYY')}
                                            </span>
                                            <div className="location">
                                                <h6 className="user-location"> {testimonial?.test_location}</h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Testimonial