import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import img1 from "../../assets/cirt/cirt1.webp"
import img2 from "../../assets/cirt/cirt2.webp"
import img3 from "../../assets/cirt/cirt3.webp"
import img4 from "../../assets/cirt/cirt4.webp"
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';

const OurCirtification = () => {
    const [ayurvedictitle, setAyurvedicTitle] = useState([]);
    const [ayurvedic, setAyurvedic] = useState([]);

    useEffect(() => {
        getAyurvedicTtitle();
        getAyurvedic();
    }, [])
    const getAyurvedicTtitle = () => {
        fetchDataFromApi('home-ayurvedic-medicine-title').then((res) => {
            if (res.status === 1) {
                setAyurvedicTitle(...[res.data]);
            }
        })
    };
    const getAyurvedic = () => {
        fetchDataFromApi('home-ayurvedic-medicine').then((res) => {
            if (res.status === 1) {
                setAyurvedic(...[res.data]);
            }
        })
    };
    return (
        <div className="firstbackgroundcolor">
            <div className="desktop">
                <Container fluid="md">
                    <div className="descHeader text-center">
                        <h2>{ayurvedictitle.medicine_heading}</h2>
                        <div className="desc">
                            <div className="text-justify" dangerouslySetInnerHTML={{ __html: ayurvedictitle.medicine_desc }}></div>
                        </div>
                    </div>
                    <Row className="cirtification">
                        {ayurvedic.map((ayurvedic, index) => (
                            <Col md={3} key={index}>
                                <div className="cirtificationbody">
                                    <Image src={baseUrl(`medicine/${ayurvedic.medicine_icon}`)} alt={ayurvedic.medicine_icon} title={ayurvedic.medicine_icon}></Image>
                                    <div className="heading">
                                        <h3>{ayurvedic.medicine_title}</h3>
                                    </div>
                                    <p className="heading">{ayurvedic.medicine_desc}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container fluid="md">
                    <div className="descHeader text-center">
                        <h2>{ayurvedictitle.medicine_heading}</h2>
                        <div className="desc">
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: ayurvedictitle.medicine_desc }}></div>
                        </div>
                    </div>
                    <Row className="cirtification">
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img1}></Image>
                                <div className="heading">
                                    <h3>GMP Certified</h3>
                                </div>
                                <p className="heading">Delivering high standard & authentic ayurvedic products</p>
                            </div>
                            <div className="cirtificationbody mt-2">
                                <Image src={img2}></Image>
                                <div className="heading">
                                    <h3>100% Organic</h3>
                                </div>
                                <p className="heading">Ingredients sourced indigenously from mountains & forests</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="cirtificationbody">
                                <Image src={img3}></Image>
                                <div className="heading">
                                    <h3>Ayush Approved</h3>
                                </div>
                                <p className="heading">Approved by Ministry of Ayush, Government of India</p>
                            </div>
                            <div className="cirtificationbody mt-2">
                                <Image src={img4}></Image>
                                <div className="heading">
                                    <h3>Full Convenience</h3>
                                </div>
                                <p className="heading">All India free shipping & cash on delivery option available</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default OurCirtification