import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Step, Stepper } from 'react-form-stepper'
import { ImTruck } from 'react-icons/im'
import { MdNotificationsActive } from 'react-icons/md'

const CheckoutLogin = () => {
    const [toggled, setToggled] = useState(true);

    const displayLoginSection = () => {
        if (toggled) {
            return <div className="account-card">
                <div className="account-title">
                    <h4>User Login</h4>
                </div>
                <div className="account-content">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <form method="post" className="checkout-login">
                                <div className="form-group">
                                    <label htmlFor="mobile">Mobile Number</label>
                                    <input type="text" name="mobile" id="mobile" placeholder="Mobile Number" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btncheckcont" name="login">Send OTP</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="secureLogin">
                                <h4>Advantages of our secure login</h4>
                                <ul>
                                    <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                    <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return <div className="account-card">
            <div className="account-title">
                <h4>Verify Mobile OTP</h4>
            </div>
            <div className="account-content">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <form method="post" className="checkout-login">
                            <div className="form-group">
                                <label htmlFor="mobileotp">Enter Mobile OTP</label>
                                <input type="text" name="mobileotp" id="mobileotp" placeholder="Enter your Mobile OTP" className="form-control" />
                            </div>
                            <a href="javascript:void(0)" className="checRresendMobileTop">Resend Mobile OTP</a>
                            <div className="form-group">
                                <button className="btncheckcont" name="login">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="secureLogin">
                            <h4>Advantages of our secure login</h4>
                            <ul>
                                <li><ImTruck /><span>Easily Track Orders, Hassle free Returns</span></li>
                                <li><MdNotificationsActive /><span>Get Relevant Alerts and Recommendation</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    };
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Checkout Login</title>
                <link rel="canonical" href="https://www.satkartar.in" />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Login</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={0}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <div className="loginBody">
                        {displayLoginSection()}
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default CheckoutLogin