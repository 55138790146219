import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Accordion from 'react-bootstrap/Accordion';
import { fetchDataFromApi } from '../utils/api'

const Faqs = () => {
    const [faq, setFaq] = useState([]);
    const pathname = window.location.href;

    useEffect(() => {
        fetchDataConfig();
    }, []);
    const fetchDataConfig = () => {
        fetchDataFromApi("faq").then((res) => {
            if (res.status === 1) {
                setFaq(...[res.data])
            }
        })
    };
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQS</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Faqs</li>
                        </ol>
                    </nav>
                    <Row className="faqsbody mt-2">
                        <div className="titledesc">
                            <h1 className="text-center">Faqs</h1>
                        </div>
                        <Accordion defaultActiveKey="0">
                            {faq?.map((faq, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{faq?.faq_question}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: faq?.faq_answer }}></div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Faqs