import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import RCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { fetchDataFromApi } from '../../utils/api';
import moment from 'moment';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const Review = () => {
    const [testimonial, setTestimonial] = useState([]);

    useEffect(() => {
        getTestimonial();
    }, []);

    const getTestimonial = () => {
        fetchDataFromApi('home-testimonial').then((res) => {
            if (res.status === 1) {
                setTestimonial(...[res.data]);
            }
        })
    }
    return (
        <div className="secondbackgroundcolor">
            <div className="desktop">
                <Container>
                    <div className="descHeader text-center">
                        <h2>What Our Customer Say About Us</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <RCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        {testimonial?.map((testimonial, index) => (
                            <Card className="homereating" key={index}>
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                        {testimonial?.test_rating} <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">{testimonial?.test_name}</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "{testimonial?.test_desc}"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                        {moment(testimonial?.created_at).format('Do MMMM YYYY')}
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> {testimonial?.test_location}</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </RCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2>What Our Customer Say About Us</h2>
                        <div className="desc">
                            <p>We manufacture, curate, and make available online, authentic ayurvedic products in India made from indigenously sourced herbs from forests and mountains across India renowned for their healing vegetations rich in medicinal properties.</p>
                        </div>
                    </div>
                    <div className="mobileReview">
                        {testimonial?.map((testimonial, index) => (
                            <Card className="homereating" key={index}>
                                <Card.Body>
                                    <div className="namerating">
                                        <span className="rating">
                                        {testimonial?.test_rating} <FaStar />
                                        </span>
                                        <div className="user-name">
                                            <h6 content="Kiara Keshvani">{testimonial?.test_name}</h6>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        "{testimonial?.test_desc}"
                                    </Card.Text>
                                    <div className="postdate">
                                        <span className="duration">
                                        {moment(testimonial?.created_at).format('Do MMMM YYYY')}
                                        </span>
                                        <div className="location">
                                            <h6 className="user-location"> {testimonial?.test_location}</h6>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </Container>
            </div>

        </div>
    )
}

export default Review