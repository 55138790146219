import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Accordion, Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaStar } from 'react-icons/fa'
import TopMarquee from '../components/homepage/TopMarquee'
import MarketPlaceMarquee from '../components/homepage/MarketPlaceMarquee'
import { getMultitpleDataApi, getProductDetailApi } from '../utils/api'
import { baseUrl } from '../utils/baseUrl'
import { numberFormat } from '../utils/numberFormate'
import { CiUser } from 'react-icons/ci'
import ReactStars from "react-rating-stars-component";
import { useCart } from "react-use-cart";
import { toast } from 'react-toastify';
import LoginPopup from '../components/LoginPopup'
import { AiOutlineShoppingCart } from 'react-icons/ai'

const ProductDetail = () => {
    const { addItem } = useCart();
    const { slug } = useParams();
    const navigate = useNavigate();
    const [productd, setProductd] = useState([]);
    const [variant, setVariant] = useState([]);
    const productid = productd?.id;
    const pathname = window.location.href;
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    const [lastScrolly, setLastScrolly] = useState(0);
    const [stickyshow, setStickyShow] = useState("hide");

    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop && pageYOffset
                < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };
    const controlFooterSticky = () => {
        if (window.scrollY > 750) {
            if (window.screenY > lastScrolly) {
                setStickyShow("hide");
            }
            else {
                setStickyShow("desktop");
            }
        }
        else {
            setStickyShow("hide");
        }
        setLastScrolly(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener("scroll", controlFooterSticky);
        return () => {
            window.addEventListener("scroll", controlFooterSticky);
        }
    }, [lastScrolly]);
    const [mimage, setMImage] = useState([]);

    const [tbenefit, setTbenefit] = useState([]);
    const [benefit, setBenefit] = useState([]);

    const [keyTitlebenefit, setKetTitlebenefit] = useState([]);
    const [keybenefit, setKeyBenefit] = useState([]);

    const [pvideotitle, setPVideoTitle] = useState([]);
    const [productvideo, setProductVideo] = useState([]);

    const [tingredient, setTIngredient] = useState([]);
    const [ingredient, setIngredient] = useState([]);

    const [thowtouse, setTHowToUse] = useState([]);
    const [howtouse, setHowToUse] = useState([]);

    const [tpdetail, setTPdetail] = useState([]);
    const [pdetail, setPdetail] = useState([]);

    const [treview, setTReview] = useState([]);
    const [review, setReview] = useState([]);

    const [tfaq, setTFaq] = useState([]);
    const [faq, setFaq] = useState([]);

    const [variantd, setVariantD] = useState(null);

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };

    const activeStyle = {
        fontWeight: 'bold',
        color: 'red',
    };
    // Content Menu
    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        getProductDetail(slug);
        if (productid) {
            getProductMoreImage(productid);
            getPBenefitTitle(productid);
            getPBenefit(productid);
            getKeyBenefitTitle(productid);
            getKeyBenefit(productid);
            getProductVideoTitle(productid);
            getProductVideo(productid);
            getPIngredientTitle(productid);
            getPIngredient(productid);
            getPHowToUseTitle(productid);
            getPHowToUse(productid);
            getPDetailTitle(productid);
            getPdetail(productid);
            getPReviewTitle(productid);
            getPReview(productid);
            getPFAQTitle(productid);
            getPFAQ(productid);
        }
    }, [slug, productid])

    const getProductDetail = (slug) => {
        getProductDetailApi(`get-product-details/${slug}`).then((res) => {
            if (res?.data.status === 1) {
                setProductd(...[res?.data.product]);
                setVariant(...[res.data?.variant]);
            }
            else {
                window.open('/*', "_self");
            }
        })
    }
    const getProductMoreImage = (productid) => {
        getMultitpleDataApi(`get-multitple-data/${productid}`).then((res) => {
            setMImage(...[res?.data.pimage]);
        })
    }
    const getPBenefitTitle = (productid) => {
        getMultitpleDataApi(`get-product-benefit-title-data/${productid}`).then((res) => {
            setTbenefit(...[res.data?.tbenefit]);
        })
    }
    const getPBenefit = (productid) => {
        getMultitpleDataApi(`get-product-benefit-data/${productid}`).then((res) => {
            setBenefit(...[res.data?.benefit]);
        })
    }
    const getKeyBenefitTitle = (productid) => {
        getMultitpleDataApi(`get-product-key-benefit-title-data/${productid}`).then((res) => {
            setKetTitlebenefit(...[res.data?.tbenefit]);
        })
    }
    const getKeyBenefit = (productid) => {
        getMultitpleDataApi(`get-product-key-benefit-data/${productid}`).then((res) => {
            setKeyBenefit(...[res.data?.benefit]);
        })
    }
    const getProductVideoTitle = (productid) => {
        getMultitpleDataApi(`get-product-video-title-data/${productid}`).then((res) => {
            setPVideoTitle(...[res.data?.tvideo]);
        })
    }
    const getProductVideo = (productid) => {
        getMultitpleDataApi(`get-product-video-data/${productid}`).then((res) => {
            setProductVideo(...[res.data?.video]);
        })
    }
    const getPIngredientTitle = (productid) => {
        getMultitpleDataApi(`get-product-ingredient-title-data/${productid}`).then((res) => {
            setTIngredient(...[res.data?.tingredient]);
        })
    }
    const getPIngredient = (productid) => {
        getMultitpleDataApi(`get-product-ingredient-data/${productid}`).then((res) => {
            setIngredient(...[res.data?.ingredient]);
        })
    }
    const getPHowToUseTitle = (productid) => {
        getMultitpleDataApi(`get-product-how-to-use-title-data/${productid}`).then((res) => {
            setTHowToUse(...[res.data?.thowtouse]);
        })
    }
    const getPHowToUse = (productid) => {
        getMultitpleDataApi(`get-product-how-to-use-data/${productid}`).then((res) => {
            setHowToUse(...[res.data?.howtouse]);
        })
    }
    const getPDetailTitle = (productid) => {
        getMultitpleDataApi(`get-product-detail-title-data/${productid}`).then((res) => {
            setTPdetail(...[res.data?.tpdetail]);
        })
    }
    const getPdetail = (productid) => {
        getMultitpleDataApi(`get-product-detail-data/${productid}`).then((res) => {
            setPdetail(...[res.data?.pdetail]);
        })
    }
    const getPReviewTitle = (productid) => {
        getMultitpleDataApi(`get-product-review-title-data/${productid}`).then((res) => {
            setTReview(...[res.data?.treview]);
        })
    }
    const getPReview = (productid) => {
        getMultitpleDataApi(`get-product-review-data/${productid}`).then((res) => {
            setReview(...[res.data?.review]);
        })
    }
    const getPFAQTitle = (productid) => {
        getMultitpleDataApi(`get-product-faq-title-data/${productid}`).then((res) => {
            setTFaq(...[res.data?.tfaq]);
        })
    }
    const getPFAQ = (productid) => {
        getMultitpleDataApi(`get-product-faq-data/${productid}`).then((res) => {
            setFaq(...[res?.data.faq]);
        });
    }
    const handleSelectVariant = (variantid) => {
        getMultitpleDataApi(`get-single-variant-details/${variantid}`).then((res) => {
            setVariantD(...[res?.data.variant]);
        });
    }
    const handleAddToCart = (variantid) => {
        if (variantid === null) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: productd?.name,
                    size: res.data.variant.variant_pack_type,
                    sku: productd?.product_sku,
                    price: res.data.variant.variant_price,
                    image: productd?.image,
                };
                addItem(product);
                toast.success("Product has been added in your cart!");
            });
        }
        else {
            const product = {
                id: productid,
                name: productd?.name,
                size: variantid?.variant_pack_type,
                sku: productd?.product_sku,
                price: variantid?.variant_price,
                image: productd?.image,
            };
            addItem(product)
            toast.success("Product has been added in your cart!")
        }
    }
    const handleBuyNow = (variantid) => {
        if (variantid === null) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: productd?.name,
                    size: res.data.variant.variant_pack_type,
                    sku: productd?.product_sku,
                    price: res.data.variant.variant_price,
                    image: productd?.image,
                };
                addItem(product);
                if (localStorage.getItem('userdata')) {
                    navigate('/delivery-address');
                }
                else {
                    setLoginPopupShow(true);
                    localStorage.setItem("userpopup", "userpopup")
                }
            });
        }
        else {
            const product = {
                id: productid,
                name: productd?.name,
                size: variantid?.variant_pack_type,
                sku: productd?.product_sku,
                price: variantid?.variant_price,
                image: productd?.image,
            };
            addItem(product);
            if (localStorage.getItem('userdata')) {
                navigate('/delivery-address');
            }
            else {
                setLoginPopupShow(true);
                localStorage.setItem("userpopup", "userpopup")
            }
        }
    }
    return (
        <div className="bg-white">
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productd?.meta_title}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={productd?.meta_title} />
                <meta name="description" content={productd?.meta_description} />
                <meta name="keywords" content={productd?.meta_keyword} />
                <meta property="og:url" content={pathname} />
                {productd?.no_follow_index_status === 1 ?
                    <meta name="robots" content="noindex,nofollow" />
                    :
                    ""
                }
                <meta property="og:title" content={productd?.meta_title} />
                <meta property="og:description" content={productd?.meta_description} />
                <link rel="preload" fetchpriority="high" as="image" href={baseUrl(`product/${productd?.image}`)} type="image/webp"></link>
            </Helmet>
            <Header />
            <div className="pdetails mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{productd?.page_title}</li>
                        </ol>
                    </nav>
                    <Row className="productDetailbody bg-white mt-2">
                        <Col>
                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={true} showIndicators={true} swipeable={true} showStatus={false}>
                                <div className="cimage">
                                    <img src={baseUrl(`product/${productd?.image}`)} alt={productd?.image} />
                                </div>
                                {mimage?.map((mimage, index) => (
                                    <div className="cimage" key={index}>
                                        <img src={baseUrl(`product/${mimage?.product_image}`)} alt={mimage?.product_image} />
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                        <Col>
                            <div className="detailBody">
                                <h1>{productd?.name}</h1>
                                <div>
                                    <div className="d-flex align-items-baseline">
                                        <small className="text-warning">
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </small>
                                        <p className="m-0 p-0">
                                            <span className="fs-5 ms-2">{Math?.floor(Math.random() * 5000)} reviews </span>
                                        </p>
                                    </div>
                                    <p>
                                        <span className="benefits_span text-dark">{productd?.product_tag_line}</span>
                                    </p>
                                    <p className="product__text_description col-lg-10 col-12">{productd?.short_desc}</p>
                                    <hr className="my-6"></hr>
                                    <div className="benefits__text mt-4">
                                        <h4>{tbenefit?.benefits_title}</h4>
                                        <div className="benefitDescicons wn-flex col-lg-12 col-12">
                                            {benefit?.map((benefit, index) => (
                                                <div className="descIcons" key={index}>
                                                    <Image src={baseUrl(`benefit/${benefit?.benefits_img}`)}></Image>
                                                    <span>{benefit?.benefits_desc}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {(() => {
                                        if (variantd != null) {
                                            return (
                                                <div className="productPrice">
                                                    <div className="pro-price d-flex">
                                                        <div className="price">
                                                            <span className="new-price">{numberFormat(variantd?.variant_price)}</span>
                                                            <span className="cutprice">
                                                                <del>{numberFormat(variantd?.variant_cut_price)}</del>
                                                            </span>
                                                            <span className="p-discount">{variantd?.variant_save_amount}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="productPrice">
                                                    <div className="pro-price d-flex">
                                                        <div className="price">
                                                            <span className="new-price">{numberFormat(productd?.price)}</span>
                                                            <span className="cutprice">
                                                                <del>{numberFormat(productd?.product_cut_price)}</del>
                                                            </span>
                                                            <span className="p-discount">{productd?.product_save}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()}
                                    <Form className=" mt-4">
                                        <div className="varientSesction d-flex">
                                            {variant?.map((variant, index) => (
                                                <div className="varient" key={index}>
                                                    <input type="radio" name="varient" id={`varient${index}`} value={variant?.id} onChange={() => handleSelectVariant(variant?.id)} />
                                                    <label className="varientBody" htmlFor={`varient${index}`}>
                                                        <div className="varientDetail">
                                                            <p className="packType">{variant?.variant_pack_type}</p>
                                                            <p className="packSave">{variant?.variant_save_amount}</p>
                                                            <h4 className="packPrice">Rs. {variant?.variant_price}</h4>
                                                            <del className="cutprice">Rs. {variant?.variant_cut_price}</del>
                                                            <h6 className="totaltablet">{variant?.variant_pack_in}</h6>
                                                            <h6 className="pertablet">{variant?.variant_per_tablet}</h6>
                                                        </div>
                                                        <p className="packname">{variant?.variant_title}</p>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {(() => {
                                            if (productd?.payment_method === "COD") {
                                                return (
                                                    <div className="buttonSection">
                                                        <Button onClick={() => handleAddToCart(variantd)} className="addtocartcod">Add To Cart</Button>
                                                    </div>
                                                )
                                            }
                                            else if (productd?.payment_method === "Online") {
                                                return (
                                                    <div className="buttonSection">
                                                        <Button onClick={() => handleBuyNow(variantd)} className="buyitnowonline">Buy It Now</Button>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div className="buttonSection">
                                                        <Button onClick={() => handleAddToCart(variantd)} className="addtocart">Add To Cart</Button>
                                                        <Button onClick={() => handleBuyNow(variantd)} className="buyitnow">Buy Now</Button>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="marquees mt-4">
                    <TopMarquee />
                </div>

                <section className="section-tab product_top bg-tab" id="navbar">
                    <div className="container">
                        <div className="all-tab">
                            <ul id="ulTabs">
                                {(() => {
                                    if (keybenefit) {
                                        return (
                                            <li className={activeSection === 'keybenefit' ? 'active' : ''}><a href="#KeyBenefits" style={activeSection ===
                                                'keybenefit' ? activeStyle : {}}>Key Benefits</a><span>|</span></li>
                                        )
                                    }
                                })()}

                                {(() => {
                                    if (productvideo) {
                                        return (
                                            <li className={activeSection === 'productvideo' ? 'active' : ''}><a href="#ProductVideo" style={activeSection ===
                                                'productvideo' ? activeStyle : {}}>Product Video</a><span>|</span></li>
                                        )
                                    }
                                })()}

                                {(() => {
                                    if (ingredient) {
                                        return (
                                            <li className={activeSection === 'ingredient' ? 'active' : ''}><a href="#Ingredients" style={activeSection ===
                                                'ingredient' ? activeStyle : {}}>Key Ingredients</a><span>|</span></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (howtouse) {
                                        return (
                                            <li className={activeSection === 'HowToUse' ? 'active' : ''}><a href="#HowToUse" style={activeSection ===
                                                'HowToUse' ? activeStyle : {}}>How To Use</a><span>|</span></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (pdetail) {
                                        return (
                                            <li className={activeSection === 'ProductDetails' ? 'active' : ''}><a href="#ProductDetails" style={activeSection ===
                                                'ProductDetails' ? activeStyle : {}}>Product Details</a><span>|</span></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (review) {
                                        return (
                                            <li className={activeSection === 'Reviews' ? 'active' : ''}><a href="#Reviews" style={activeSection ===
                                                'Reviews' ? activeStyle : {}}>Reviews</a><span>|</span></li>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (faq) {
                                        return (
                                            <li className={activeSection === 'faqs' ? 'active' : ''}><a href="#faqs" style={activeSection ===
                                                'faqs' ? activeStyle : {}}>FAQs</a></li>
                                        )
                                    }
                                })()}
                            </ul>
                        </div>
                    </div>
                </section>

                <div id="KeyBenefits">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mt-4 mb-4">
                                <h2 className="">{keyTitlebenefit?.keybenefit_title}</h2>
                            </div>
                            <div className="key-benefit">
                                <div className="row">
                                    {keybenefit?.map((keybenefit, index) => (
                                        <div className="col-lg-3 col-sm-6 col-6" key={index}>
                                            <div className="use-card text-center">
                                                <img src={baseUrl(`benefit/${keybenefit?.key_img}`)} />
                                                <p>{keybenefit?.key_desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div id="ProductVideo">
                    <section className="section-all">
                        <Container>
                            <div className="t-tittle text-center mt-4 mb-4">
                                <h2 className="">{pvideotitle?.pvideo_title}</h2>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="video">
                                        <iframe width="100%" height="350" src={productvideo?.pvideo_url} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="descingredients">
                                        <div dangerouslySetInnerHTML={{ __html: productvideo?.pvideo_desc }}></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
                <div id="Ingredients">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mt-4 mb-4">
                                {tingredient ?
                                    <h2>{tingredient?.ingredient_title}</h2>
                                    :
                                    <h2>KEY INGREDIENTS</h2>
                                }
                            </div>
                            <div className="key-ingredient">
                                <div className="row justify-content-center">
                                    {ingredient?.map((ingredient, index) => (
                                        <div className="col-lg-3 col-sm-6 col-6 mb-4 text-center" key={index}>
                                            <div className="ing-box">
                                                <img src={baseUrl(`ingredient/${ingredient?.ingredient_img}`)} alt="Safed Musli" />
                                            </div>
                                            <div className="ing-box-text">
                                                <h4 className="my-1">{ingredient?.ingredient_title}</h4>
                                                <p className="mb-0 small">{ingredient?.ingredient_desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="HowToUse">
                    <section className="steps section-all">
                        <div className="container">
                            <div className="t-tittle text-center mb-5">
                                {thowtouse ?
                                    <h2>{thowtouse?.how_to_use_title}</h2>
                                    :
                                    <h2>How To Use</h2>
                                }
                            </div>
                            <div className="row align-items-center pb-md-3">
                                <div className="col-md-6 col-lg-6 mb-4 mb-md-0">
                                    <img className="rounded-1 w-100" src={baseUrl(`use/${howtouse?.how_use_img}`)} alt={howtouse?.how_use_img} />
                                </div>
                                <div className="col-md-6 col-lg-6 mobilehow">
                                    <div className="step">
                                        <div dangerouslySetInnerHTML={{ __html: howtouse?.how_to_use }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="ProductDetails">
                    <section className="section-product-details section-all">
                        <div className="container">
                            <div className="t-tittle mt-4 mb-4">
                                {tpdetail ?
                                    <h2>{tpdetail?.detail_title}</h2>
                                    :
                                    <h2>Product Details</h2>
                                }
                            </div>
                            <div className="row product-inner-part px-40">
                                <div className="col-lg-12 proven-part">
                                    {isReadMore ?
                                        <div className="catdesps" dangerouslySetInnerHTML={{ __html: pdetail?.detail_desc?.slice(0, 350) }}></div>
                                        : <div className="catdesps" dangerouslySetInnerHTML={{ __html: pdetail?.detail_desc }}></div>}
                                    {pdetail?.detail_desc?.length > 350 &&
                                        <button className="btnreadmore" onClick={toggleReadMore}>
                                            {isReadMore ? 'Read more' : ' Read less'}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="steps">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mb-5">
                                <h2 className=""><b>Results you can</b>  <span>expect</span></h2>
                            </div>
                            <div className="our-certification">
                                <div className="row justify-content-center">
                                    <ul className="step step-sm step-icon-lg step-centered mb-10">
                                        <li className="step-item">
                                            <div className="step-content-wrapper align-items-center">
                                                <span className="step-icon step-icon-border">
                                                    <span className="svg-icon text-primary">
                                                        <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" alt="" />
                                                    </span>
                                                </span>
                                                <div className="step-content">
                                                    <h4 className="step-title">Day 1</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="step-item">
                                            <div className="step-content-wrapper align-items-center">
                                                <span className="step-icon step-icon-border">
                                                    <span className="svg-icon text-primary">
                                                        <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets//images/ayush.webp" alt="" />
                                                    </span>
                                                </span>
                                                <div className="step-content">
                                                    <h4 className="step-title">Day 10 - 15</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="step-item">
                                            <div className="step-content-wrapper align-items-center">
                                                <span className="step-icon step-icon-border">
                                                    <span className="svg-icon text-primary">
                                                        <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" alt="" />
                                                    </span>
                                                </span>
                                                <div className="step-content">
                                                    <h4 className="step-title">1 Month</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="step-item">
                                            <div className="step-content-wrapper align-items-center">
                                                <span className="step-icon step-icon-border">
                                                    <span className="svg-icon text-primary">
                                                        <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" alt="" />
                                                    </span>
                                                </span>
                                                <div className="step-content">
                                                    <h4 className="step-title">2 Months</h4>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id="Reviews">
                    <section className="section-all">
                        <Container>
                            <div className="t-tittle text-center mt-4 mb-4">
                                {treview ?
                                    <h2>{treview?.reviews_title}</h2>
                                    :
                                    <h2>Reviews</h2>
                                }
                            </div>
                            <Row>
                                {review?.map((review, index) => (
                                    <Col md={4} className="mb-2 mt-2" key={index}>
                                        <div className="card card-body border ratingcard">
                                            <p className="mb-2 reviewdesc">“{review?.user_desc}”</p>
                                            <ul className="list-inline mb-1">
                                                <ReactStars
                                                    count={5}
                                                    size={20}
                                                    activeColor="#ff9f00"
                                                    isHalf={true}
                                                    value={review?.user_rating}
                                                    readonly={true}
                                                />
                                            </ul>
                                            <div className="d-flex align-items-center">
                                                <div className="avatar avatar-xs me-2">
                                                    <CiUser />
                                                </div>
                                                <small className="mb-0 heading-color fw-semibold">{review?.user_name}</small>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </section>
                </div>
                <div id="Certification">
                    <section className="section-all">
                        <div className="container">
                            <div className="t-tittle text-center mb-5">
                                <h2 className="">Our <span>Certification</span></h2>
                            </div>
                            <div className="our-certification">
                                <div className="row justify-content-center">
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/ayush.webp" alt="Ayush" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/gmp.webp" alt="GMP" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/iso.webp" alt="ISO" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src="https://sunil.drpilesfree.com/nikhil-ppc1/assets/images/madeinindia.webp" alt="Made in India" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
                <div className="faqs">
                    <Container className="qus-bg">
                        <div className="t-tittle text-center mt-4 mb-4">
                            {tfaq ?
                                <h2 >{tfaq?.faq_title}</h2>
                                :
                                <h2>FAQS</h2>
                            }
                        </div>
                        <div className="faqbody">
                            <Accordion defaultActiveKey="0">
                                {faq?.map((faq, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{faq?.faq_question}</Accordion.Header>
                                        <Accordion.Body>
                                            <div dangerouslySetInnerHTML={{ __html: faq?.faq_desc }}></div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Container>
                </div>
                <MarketPlaceMarquee />
            </div>
            <Footer />
            {(() => {
                if (productd?.price != 0) {
                    return (
                        <section className={`fact-area ${stickyshow} `}>
                            <div className="container">
                                <div className="col-12 col-lg-12 pt-1">
                                    <div className="">
                                        <h4 className="sticky-title">{productd?.page_title}</h4>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col-6 col-lg-6 pt-1 d-flex">
                                        <div className="stickyprice">
                                            <span className="sticky-price"><b>{numberFormat(productd?.price)}</b></span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6 pt-1 d-flex" style={{ justifyContent: "space-between" }}>
                                        <div className="sticky-buy-now" id="cartsticky">
                                            <span className="">
                                                <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                            </span>
                                            <button onClick={() => handleBuyNow(variantd)}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            })()}
            {(() => {
                if (productd?.price != 0) {
                    return (
                        <section className="fact-area mobile">
                            <div className="container">
                                <div className="col-12 col-lg-12 pt-1">
                                    <div className="">
                                        <h4 className="sticky-title">{productd?.page_title}</h4>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col-6 col-lg-6 pt-1 d-flex">
                                        <div className="stickyprice">
                                            <span className="sticky-price"><b>{numberFormat(productd?.price)}</b></span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6 pt-1 d-flex" style={{ justifyContent: "space-between" }}>
                                        <div className="sticky-buy-now" id="cartsticky">
                                            <span className="">
                                                <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                            </span>
                                            <button onClick={() => handleBuyNow(variantd)}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            })()}
        </div>
    )
}

export default ProductDetail