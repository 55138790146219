import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import UserSideBar from '../components/UserSideBar'
import { getSingleDataApi, updateUserProfileAPi, userUploadProfilePicAPi } from '../utils/api';
import { baseUserUrl } from '../utils/baseUrl';
import { toast } from 'react-toastify'

const initialState = {
    name: '',
    email: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    address: '',
}

const UserDashboard = () => {
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const [file, setFile] = useState('');
    const [values, setInputValues] = useState(initialState);
    const pathname = window.location.href;

    useEffect(() => {
        if (userid) {
            getSingleUserdata(userid)
        }
        else {
            navigate("/login");
        }
    }, [userid]);
    const getSingleUserdata = (userid) => {
        getSingleDataApi(`get-single-data/${userid}`).then((res) => {
            if (res.data.status === 1) {
                ;
                setInputValues(...[res.data.userdata])
            }
        })
    }
    const updateUserProfile = async (e) => {
        e.preventDefault();
        try {
            updateUserProfileAPi(`update-user-profile/${userid}`, values).then((res) => {
                if (res.data.status === 1) {
                    toast.success("profile has been updated successfully!!");
                }
                else {
                    toast.error("Unable to fetched user data");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    // Update Image
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("foto", file);
                userUploadProfilePicAPi(`update-user-profile-pic/${userid}`, data).then((res) => {
                    if (res.status===200) {
                        getSingleUserdata(userid);
                        toast.success("profile pic updated sussfully");
                    }
                    else {
                        toast.error("Unable to fetched picture data");
                    }
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);

    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::My Account</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content="user account" />
                <meta name="description" content="user account" />
                <meta name="keyword" content="user account" />
                <meta property="og:url" content={pathname} />
            </Helmet>
            <Header />
            <div className="myaccount-section mb-4 bg-white">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabindex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">user dashboard</li>
                        </ol>
                    </nav>
                    <Row className="userdashboard mt-4">
                        <Col md={3}>
                            <UserSideBar />
                        </Col>
                        <Col md={9}>
                            <h3 className="fomes">Edit User Profile</h3>
                            <div className="contact-form-div">
                                <form method="post" encType="multipart/form-data" onSubmit={updateUserProfile}>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3">
                                            <img src={baseUserUrl(`user/${values.foto}`)} className="imgProfilePic" />
                                            <label className="labelProfilePic" htmlFor="foto">Change Profile Picture</label>
                                            <input className="profilePic" type="file" onChange={(e) => setFile(e.target.files[0])} id='foto' name="foto" accept="image/*" />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Name</label>
                                                    <input name="name" id="name" onChange={handleInputs} placeholder="Enter Name..." className="form-control" type="text" required="true" value={values.name} />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Email</label>
                                                    <input placeholder="Enter Email..." type="email" name="email" id="email" onChange={handleInputs} className="form-control" value={values.email} required="true" />
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12">
                                                    <label>Mobile</label>
                                                    <input placeholder="Enter here..." type="text" name="mobile" id="mobile" className="form-control" value={values.mobile} readOnly="true" />
                                                </div>
                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                    <button className="userBtn mt-0">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default UserDashboard