import React, { useEffect, useState } from 'react'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { fetchDataFromApi, getMultitpleDataApi, productFilterByCategoryAPi } from '../utils/api'
import { numberFormat } from '../utils/numberFormate'
import { baseUrl } from '../utils/baseUrl'
import { useCart } from 'react-use-cart'
import LoginPopup from '../components/LoginPopup'
import { toast } from 'react-toastify';


const Product = () => {
    const { addItem } = useCart();
    const navigate = useNavigate();
    const pathname = window.location.href;
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [producttitle, setProductTitle] = useState([]);
    const [loginPopupShow, setLoginPopupShow] = useState(false);

    useEffect(() => {
        getProduct();
        getCategory();
        getProductPageTilte();
    }, [])

    const getProductPageTilte = () => {
        fetchDataFromApi('get-product-page-title').then((res) => {
            if (res.status === 1) {
                setProductTitle(...[res.data]);
            }
        })
    }
    const getProduct = (catid) => {
        if (catid) {
            if (catid === "all") {
                fetchDataFromApi('home-starter-product').then((res) => {
                    if (res.status === 1) {
                        setProduct(...[res.data]);
                    }
                })
            }
            else {
                productFilterByCategoryAPi(`product-ayurvedic-filter-by-category/${catid}`).then((res) => {
                    if (res.data.status === 1) {
                        setProduct(...[res.data.data]);
                    }
                })
            }
        }
        else {
            fetchDataFromApi('home-starter-product').then((res) => {
                if (res.status === 1) {
                    setProduct(...[res.data]);
                }
            })
        }
    }
    const getCategory = () => {
        fetchDataFromApi('home-category').then((res) => {
            if (res.status === 1) {
                setCategory(...[res.data]);
            }
        })
    }
    const handleAddToCart = (productid,pname,psku,pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image:pimage,
                };
                addItem(product);
                toast.success("Product has been added in your cart!");
            });
        }
    }
    const handleBuyNow = (productid,pname,psku,pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image:pimage,
                };
                addItem(product);
                if(localStorage.getItem('userdata')){
                    navigate('/delivery-address');
                }
                else{
                    setLoginPopupShow(true);
                    localStorage.setItem("userpopup","userpopup")
                }
            });
        }
    }
    return (
        <div className="products themebackgroundcolor">
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{producttitle?.product_heading}</title>
                <link rel="canonical" href={pathname} />
                <meta name="title" content={producttitle?.product_heading} />
                <meta name="description" content={producttitle?.product_desc} />
                <meta name="keywords" content="" />
                <meta property="og:url" content={pathname} />
                <meta property="og:title" content={producttitle?.product_heading} />
                <meta property="og:description"
                    content={producttitle?.product_desc} />
                <meta name="description" content={producttitle?.product_desc} />
            </Helmet>
            <Header />
            <div className="productseaction mt-2 mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">products</li>
                        </ol>
                    </nav>
                    <Row>
                        <Col md={3} sm={12}>
                            <div className="shop-widget">
                                <h6 className="shop-widget-title">Filter by Probelm</h6>
                                <form method="post" className="filterForm">
                                    <ul className="shop-widget-list shop-widget-scroll">
                                        {category?.map((category, index) => (
                                            <li key={index}>
                                                <div className="shop-widget-content">
                                                    <input type="radio" id={`category-${category?.id}`} value={category?.id} name="pcategory" onChange={() => getProduct(category.id)} />
                                                    <label htmlFor={`category-${category?.id}`}>{category?.category_name}</label>
                                                </div>
                                            </li>
                                        ))}
                                        <li>
                                            <div className="shop-widget-content">
                                                <input type="radio" id="all" value="all" name="pcategory" onChange={() => getProduct("all")} />
                                                <label htmlFor="all">All</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </Col>
                        <Col md={9} sm={12}>
                            <div className="productTopBar">
                                <div className="productViewTitle">
                                    <h4>Ayurveda Products</h4>
                                </div>
                            </div>
                            <Row>
                                {product ? product.map((product, index) => (
                                    <Col md={4} sm={6} key={index}>
                                        <Card className="product-body">
                                            <Link to={`/products/${product?.product_slug_url}`}>
                                                <Card.Img variant="top" src={baseUrl(`product/${product?.image}`)} alt={product?.image} />
                                            </Link>
                                            <Card.Body>
                                                <Link to={`/products/${product?.product_slug_url}`}>
                                                    <Card.Title>{product?.page_title}</Card.Title>
                                                </Link>
                                                <Card.Text>
                                                    <div className="offersection">
                                                        <div className="priceSection">
                                                            <span className="price">{numberFormat(product?.price)}</span>
                                                            <del className="cutprice">{numberFormat(product?.product_cut_price)}</del>
                                                        </div>
                                                        <div className="offer">
                                                            <span>{product?.product_save}</span>
                                                        </div>
                                                    </div>
                                                    <div className="ratingSection">
                                                        <span className="rating">
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStarHalfAlt />
                                                        </span>
                                                        <span className="totalreview">{Math?.floor(Math.random() * 5000)} reviews</span>
                                                    </div>
                                                </Card.Text>
                                                <div className="buttonSection">
                                                    <Button onClick={() => handleAddToCart(product?.id, product?.name, product?.product_sku, product?.image)} className="addtocart">Add To Cart</Button>
                                                    <Button onClick={() => handleBuyNow(product?.id, product?.name, product?.product_sku, product?.image)} className="buyitnow">Buy Now</Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                                    :
                                    <div>Data Not founds</div>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Product