import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import BlogCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from 'react-bootstrap/Card';

import blog1 from "../../assets/blog/blog1.webp"
import blog2 from "../../assets/blog/blog2.webp"
import blog3 from "../../assets/blog/blog3.webp"
import blog4 from "../../assets/blog/blog4.webp"
import blog5 from "../../assets/blog/blog5.webp"
import { Link } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const HomeBlog = () => {
    const [blogtitle, setBlogTitle] = useState([]);
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        getBlogTitle();
        getBlog();
    }, [])

    const getBlogTitle = () => {
        fetchDataFromApi('home-blog-title').then((res) => {
            if (res.status === 1) {
                setBlogTitle(...[res.data]);
            }
        })
    };

    const getBlog = () => {
        fetchDataFromApi('home-blog').then((res) => {
            if (res.status === 1) {
                setBlog(...[res.data]);
            }
        })
    }
    return (
        <div className="firstbackgroundcolor">
            <div className="desktop">
                <Container>
                    <div className="descHeader text-center">
                        <h2>{blogtitle?.blog_heading}</h2>
                        <div className="desc">
                            <div className="text-justify" dangerouslySetInnerHTML={{ __html: blogtitle?.blog_desc }}></div>
                        </div>
                    </div>
                    <BlogCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        {blog.map((blog, index) => (
                            <Card className="homeblog" key={index}>
                                <Card.Img variant="top" src={baseUrl(`blog/${blog?.blog_img}`)} />
                                <Card.Body>
                                    <Card.Title><FaUserAlt />Author:{blog?.blog_auther}</Card.Title>
                                    <Card.Text>
                                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: blog?.blog_title }}></div>
                                    </Card.Text>
                                    <Link to={blog?.blog_slug_url}>Read More</Link>
                                </Card.Body>
                            </Card>
                        ))}
                    </BlogCarousel>
                </Container>
            </div>
            <div className="mobile mobileconcern">
                <Container>
                    <div className="descHeader text-center">
                        <h2>{blogtitle?.blog_heading}</h2>
                        <div className="desc">
                            <div className="text-justify" dangerouslySetInnerHTML={{ __html: blogtitle?.blog_desc }}></div>
                        </div>
                    </div>
                    <div className="mobilehomeblog">
                        {blog.map((blog, index) => (
                            <Card className="homeblog" key={index}>
                                <Card.Img variant="top" src={baseUrl(`blog/${blog?.blog_img}`)} />
                                <Card.Body>
                                    <Card.Title><FaUserAlt />Author:{blog?.blog_auther}</Card.Title>
                                    <Card.Text>
                                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: blog?.blog_title }}></div>
                                    </Card.Text>
                                    <Link to={blog?.blog_slug_url}>Read More</Link>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default HomeBlog