import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import BCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { fetchDataFromApi, getMultitpleDataApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';
import { numberFormat } from '../../utils/numberFormate';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import LoginPopup from '../LoginPopup';
import { toast } from 'react-toastify';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3020, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const BestSeller = () => {
    const { addItem } = useCart();
    const [bestTitle, SetBestTitle] = useState([]);
    const [bestseller, setBestSeller] = useState([]);
    const [loginPopupShow, setLoginPopupShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getBestSellerTitle();
        getBestSeller();
    }, []);
    const getBestSellerTitle = () => {
        fetchDataFromApi('home-starter-product-title').then((resp) => {
            SetBestTitle(...[resp.data]);
        })
    }
    const getBestSeller = () => {
        fetchDataFromApi('best-seller-products').then((resp) => {
            setBestSeller(...[resp.data]);
        })
    }
    const handleAddToCart = (productid,pname,psku,pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image:pimage,
                };
                addItem(product);
                toast.success("Product has been added in your cart!");
            });
        }
    }
    const handleBuyNow = (productid,pname,psku,pimage) => {
        if (productid) {
            getMultitpleDataApi(`get-single-variant-details-with-product/${productid}`).then((res) => {
                const product = {
                    id: productid,
                    name: pname,
                    size: res.data.variant.variant_pack_type,
                    sku: psku,
                    price: res.data.variant.variant_price,
                    image:pimage,
                };
                addItem(product);
                if(localStorage.getItem('userdata')){
                    navigate('/delivery-address');
                }
                else{
                    setLoginPopupShow(true);
                    localStorage.setItem("userpopup","userpopup")
                }
            });
        }
    }
    return (
        <div className="secondbackgroundcolor">
            <LoginPopup
                show={loginPopupShow}
                onHide={() => setLoginPopupShow(false)}
            />
            <div className="desktop">
                <Container className="bestSellerSection">
                    <div className="descHeader text-center">
                        <h2>{bestTitle?.product_heading}</h2>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{ __html: bestTitle?.product_desc }}></div>
                        </div>
                    </div>
                    <BCarousel responsive={responsive} infinite={true} arrows={true} showDots={false}>
                        {bestseller?.map((bestseller, index) => (
                            <div className="bestseller-body" key={index}>
                                <Card>
                                    <Link to={`/products/${bestseller?.product_slug_url}`}>
                                        <Card.Img variant="top" src={baseUrl(`product/${bestseller?.image}`)} />
                                    </Link>
                                    <Card.Body>
                                        <Link to={`/products/${bestseller?.product_slug_url}`}>
                                            <Card.Title>{bestseller?.page_title}</Card.Title>
                                        </Link>
                                        <Card.Text>
                                            <div className="offersection">
                                                <div className="priceSection">
                                                    <span className="price">{numberFormat(bestseller?.price)}</span>
                                                    <del className="cutprice">{numberFormat(bestseller?.product_cut_price)}</del>
                                                </div>
                                                <div className="offer">
                                                    <span>{bestseller?.product_save}</span>
                                                </div>
                                            </div>
                                            <div className="ratingSection">
                                                <span className="rating">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStarHalfAlt />
                                                </span>
                                                <span className="totalreview">{Math?.floor(Math.random() * 5000)} reviews</span>
                                            </div>
                                        </Card.Text>
                                        <div className="buttonSection">
                                            <Button onClick={() => handleAddToCart(bestseller?.id,bestseller?.name, bestseller?.product_sku, bestseller?.image)} className="addtocart">Add To Cart</Button>
                                            <Button onClick={() => handleBuyNow(bestseller?.id,bestseller?.name, bestseller?.product_sku, bestseller?.image)} className="buyitnow">Buy Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </BCarousel>
                </Container>
            </div>
            <div className="mobile">
                <Container className="bestSellerSection">
                    <div className="descHeader text-center">
                        <h2>{bestTitle?.product_heading}</h2>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{ __html: bestTitle?.product_desc }}></div>
                        </div>
                    </div>
                    <div className="mobilebestseller">
                        {bestseller?.map((bestseller, index) => (
                            <div className="bestseller-body" key={index}>
                                <Card>
                                    <Link to={`/products/${bestseller?.product_slug_url}`}>
                                        <Card.Img variant="top" src={baseUrl(`product/${bestseller?.image}`)} />
                                    </Link>
                                    <Card.Body>
                                        <Link to={`/products/${bestseller?.product_slug_url}`}>
                                            <Card.Title>{bestseller?.page_title}</Card.Title>
                                        </Link>
                                        <Card.Text>
                                            <div className="offersection">
                                                <div className="priceSection">
                                                    <span className="price">{numberFormat(bestseller?.price)}</span>
                                                    <del className="cutprice">{numberFormat(bestseller?.product_cut_price)}</del>
                                                </div>
                                                <div className="offer">
                                                    <span>{bestseller?.product_save}</span>
                                                </div>
                                            </div>
                                            <div className="ratingSection">
                                                <span className="rating">
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStar />
                                                    <FaStarHalfAlt />
                                                </span>
                                                <span className="totalreview">{Math?.floor(Math.random() * 5000)} reviews</span>
                                            </div>
                                        </Card.Text>
                                        <div className="buttonSection">
                                            <Button onClick={() => handleAddToCart(bestseller?.id,bestseller?.name, bestseller?.product_sku, bestseller?.image)} className="addtocart">Add To Cart</Button>
                                            <Button onClick={() => handleBuyNow(bestseller?.id,bestseller?.name, bestseller?.product_sku, bestseller?.image)} className="buyitnow">Buy Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default BestSeller