import React, { useEffect, useState } from 'react'
import { getSingleDataApi } from '../utils/api';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { FaRegEdit, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import Header from '../components/Header.';
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../components/Footer';

const OrderAddress = () => {
    const mobile = localStorage.getItem("usermobile");
    const pathname = window.location.href;

    const [address, setAddress] = useState([])

    useEffect(() => {
        getDeliveryAddress(mobile)
    }, [])
    const getDeliveryAddress = (mobile) => {
        getSingleDataApi(`get-delivery-here-address/${mobile}`).then((res) => {
            if (res.data.status === 1) {

                setAddress(...[res.data.address]);
            }
        })
    }
    const removeDeliveryAddress = (removeid) => {
        getSingleDataApi(`remove-delivery-here-address/${removeid}`).then((res) => {
            if (res.data.status === 1) {
                getDeliveryAddress(mobile)
                toast.success("Delivery address has been removed!")
            }
        })
    }

    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="oderaddress-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item" to="/">
                                <Link to="/my-account" role="button" tabIndex="1">My Account</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Order Address</li>
                        </ol>
                    </nav>
                    <Row className="addressbody">
                        <h1 className="text-center">Delivery Address</h1>
                        <Col>
                            <div className="orderAddress">
                                {address?.map((address, index) => (
                                    <div className="addressSection" key={index}>
                                        <div className="row">
                                            <div className="addressHeading">
                                                <h3><IoPerson /> {address?.delivery_name}</h3>
                                                <h3>
                                                    <button onClick={() => removeDeliveryAddress(address?.id)} className="btnaddressremove"><FaTrashAlt /></button>
                                                </h3>
                                            </div>
                                            <div className="col-md-6 addressBody">
                                                <div className="ordertitle">
                                                    <span className="name">Mobile:</span>
                                                    <span className="namedetail">{address?.delivery_mobile}</span>
                                                </div>
                                                <div className="ordertitle">
                                                    <span className="name">Address:</span>
                                                    <span className="namedetail">{address?.delivery_addresse}</span>
                                                </div>
                                                <div className="ordertitle">
                                                    <span className="name">State:</span>
                                                    <span className="namedetail">{address?.delivery_state}</span>
                                                </div>
                                                <div className="ordertitle">
                                                    <span className="name">Country:</span>
                                                    <span className="namedetail">{address?.delivery_country}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 addressBody">
                                                <div className="ordertitle">
                                                    <span className="name">Landmark:</span>
                                                    <span className="namedetail">{address?.delivery_landmark}</span>
                                                </div>
                                                <div className="ordertitle">
                                                    <span className="name">City:</span>
                                                    <span className="namedetail">{address?.delivery_city}</span>
                                                </div>
                                                <div className="ordertitle">
                                                    <span className="name">Pincode:</span>
                                                    <span className="namedetail">{address?.delivery_pincode}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default OrderAddress