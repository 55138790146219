import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import offer from "../../assets/offer/1bottle-fruits.webp"
import { Image } from 'react-bootstrap';

const OfferBanner = () => {
    return (
        <div className="offerBanner">
            <div className="desktop">
                <Container className="offerbg">
                    <Row>
                        <Col xs={7} className="text-center text-md-start drdesc">
                            <h2 className="heading-3 mb-4">Every 6 Bottles Order <br /> Gets FREE Shipping Too!</h2>
                            <p>*96% Of Customers Order 6 Bottles (Our Recommended Option)</p>
                            <p>60-Day Money Back Guarantee 100% Satisfaction Guaranteed</p>
                            <div className="btns">
                                <button className="btntord"> Order Now</button>
                            </div>
                        </Col>
                        <Col className="image">
                            <Image className="img-fluid" src={offer}></Image>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="mobile mobileoffer">
                <Container className="offerbg">
                    <Row>
                        <Col className="text-center text-md-start drdesc">
                            <Image className="img-fluid" src={offer}></Image>
                            <h2 className="heading-3 mb-4">Every 6 Bottles Order <br /> Gets FREE Shipping Too!</h2>
                            <p>*96% Of Customers Order 6 Bottles (Our Recommended Option)</p>
                            <p>60-Day Money Back Guarantee 100% Satisfaction Guaranteed</p>
                            <div className="btns">
                                <button className="btntord"> Order Now</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default OfferBanner