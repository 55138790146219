import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import login1 from "../assets/login/login1.png"
import login2 from "../assets/login/login2.png"
import login3 from "../assets/login/login3.png"
import login4 from "../assets/login/login4.png"
import login5 from "../assets/login/login5.png"
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLoginAPi, userVerifyMobileOTPAPi } from '../utils/api';

const LoginPopup = (props) => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [otpvalidated, setOTPValidated] = useState(false);
    const [toggled, setToggled] = useState(true);
    const [values, setOTPValue] = useState();
    const [mobile, setMobileValue] = useState();
    const [userlogin, setUserLogin] = useState(false);

    const handleLoginSubmit = (event) => {
        setUserLogin(true)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setUserLogin(false)
        }
        else {
            const umobile = mobile?.usermobile;
            userLoginAPi("user-send-mobile-otp", umobile).then((res) => {
                if (res.data.status === 1) {
                    setUserLogin(false)
                    localStorage.setItem("usermobile", umobile)
                    toast.success("Mobile OTP has been send succssfully");
                    setToggled(!toggled);
                }
                else {
                    setUserLogin(false)
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        setValidated(true);
    };
    const handleUserInputs = e => {
        setMobileValue({
            ...mobile,
            [e.target.name]: e.target.value,
        });
    };
    const handleMobileOtpSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const mobile = localStorage.getItem("usermobile");
            const mobiledata = {
                mobile,
                values,
            };
            userVerifyMobileOTPAPi("user-verify-mobile-otp", mobiledata).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("userdata", JSON.stringify(res.data.userdata))
                    toast.success("Your mobile OTP has been verified!!");
                    if(localStorage.getItem("userpopup") === "userpopup"){
                        navigate('/delivery-address');
                    }
                    else{
                        navigate('/my-account');
                    }
                }
                else {
                    toast.error("Unable to verify mobile OTP");
                }
            })
        }
        setOTPValidated(true);
    };
    const handleChangeNumber = () => {
        setToggled(true)
    }
    const handleInputs = e => {
        setOTPValue({
            ...values,
            [e.target.name]: e.target.value,
        });
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (value.length >= maxLength) {
            if (fieldIntIndex) {
                const nextfield = document.querySelector(
                    `input[name=otp-${fieldIntIndex + 1}]`
                );
                if (nextfield !== null) {
                    nextfield.focus();
                }
            }
        }
    };
    const resendMobileOTP = () => {
        const mobile = localStorage.getItem("usermobile");
        if (mobile.length === 10) {
            userLoginAPi("user-resend-mobile-otp", mobile).then((res) => {
                if (res.data.status === 1) {
                    toast.success("Mobile OTP has been send succssfully");
                }
                else {
                    toast.error("Unable sent mobile OTP");
                }
            })
        }
        else {
            toast.error("Invalid mobile number");
        }
    }
    const displayLoginSection = () => {
        if (toggled) {
            return <div className="loginBody">
                <h4>Signin OR Signup</h4>
                <span className="getaccess">Get access to your orders, doctor consultations</span>
                <Form noValidate validated={validated} onSubmit={handleLoginSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Enter the mobile number</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter the mobile number"
                                name="usermobile"
                                pattern="[6789][0-9]{9}"
                                id="usermobile"
                                maxLength={10}
                                minLength={10}
                                onChange={handleUserInputs}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid mobile number.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {userlogin ?
                        <Button type="submit" className="btnlogin" disabled={true}>Sending OTP...</Button>
                        :
                        <Button type="submit" className="btnlogin">Send Mobile OTP</Button>
                    }
                </Form>
                <div className="loginBottom">
                    <div className="policy">
                        <p>By logging in, you agree to our</p>
                        <p><Link to="/">Terms and Conditions</Link> & <Link to="/">Privacy Policy</Link></p>
                    </div>
                    <div className="needhelp">
                        <Link to="/contact-us">Need Help? Get In Touch</Link>
                    </div>
                </div>
            </div>
        }
        return <div className="otpBody">
            <h4>Signin OR Signup</h4>
            <span className="getaccess">Get access to your orders, doctor consultations</span>
            <Form noValidate validated={otpvalidated} onSubmit={handleMobileOtpSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Label>Enter the mobile OTP</Form.Label>
                        <div className="otpform">
                            <Form.Control
                                required
                                type="text"
                                placeholder=""
                                name="otp-1"
                                pattern="[0-9]*"
                                maxLength={1}
                                minLength={1}
                                width={10}
                                onChange={handleInputs}
                                length="1"
                            />
                            <Form.Control
                                required
                                type="text"
                                placeholder=""
                                name="otp-2"
                                pattern="[0-9]*"
                                maxLength={1}
                                minLength={1}
                                width={10}
                                onChange={handleInputs}
                                length="1"
                            />
                            <Form.Control
                                required
                                type="text"
                                placeholder=""
                                name="otp-3"
                                pattern="[0-9]*"
                                maxLength={1}
                                minLength={1}
                                width={10}
                                onChange={handleInputs}
                                length="1"
                            />
                            <Form.Control
                                required
                                type="text"
                                placeholder=""
                                name="otp-4"
                                pattern="[0-9]*"
                                maxLength={1}
                                minLength={1}
                                width={10}
                                onChange={handleInputs}
                                length="1"
                            />
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid mobile number.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Button type="submit" className="btnlogin">Login</Button>
                <div className="changeBtn">
                    <span onClick={resendMobileOTP}>Re-send mobile OTP</span>
                    <span onClick={handleChangeNumber}>Change mobile number</span>
                </div>
            </Form>
            <div className="otpBottom">
                <div className="policy">
                    <p>By logging in, you agree to our</p>
                    <p><Link to="/">Terms and Conditions</Link> & <Link to="/">Privacy Policy</Link></p>
                </div>
                <div className="needhelp">
                    <Link to="/contact-us">Need Help? Get In Touch</Link>
                </div>
            </div>
        </div>;
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row>
                    <Col md={6} className="loginslider desktop">
                        <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                            <div className="loginCarousel">
                                <div className="img">
                                    <img src={login1} />
                                </div>
                                <h5>First slide label</h5>
                                <p>Get medicine information, order medicines, book lab tests and consult doctors online from the comfort of your home.</p>
                            </div>
                            <div className="loginCarousel">
                                <div className="img">
                                    <img src={login2} />
                                </div>
                                <h5>First slide label</h5>
                                <p>Get medicine information, order medicines, book lab tests and consult doctors online from the comfort of your home.</p>
                            </div>
                            <div className="loginCarousel">
                                <div className="img">
                                    <img src={login3} />
                                </div>
                                <h5>First slide label</h5>
                                <p>Get medicine information, order medicines, book lab tests and consult doctors online from the comfort of your home.</p>
                            </div>
                            <div className="loginCarousel">
                                <div className="img">
                                    <img src={login4} />
                                </div>
                                <h5>First slide label</h5>
                                <p>Get medicine information, order medicines, book lab tests and consult doctors online from the comfort of your home.</p>
                            </div>
                            <div className="loginCarousel">
                                <div className="img">
                                    <img src={login5} />
                                </div>
                                <h5>First slide label</h5>
                                <p>Get medicine information, order medicines, book lab tests and consult doctors online from the comfort of your home.</p>
                            </div>
                        </Carousel>
                    </Col>
                    <Col md={6} sm={12} className="loginContent">
                        {displayLoginSection()}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default LoginPopup