import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header.'
import Footer from '../components/Footer'
import { Step, Stepper } from 'react-form-stepper'
import { Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import { toast } from 'react-toastify';
import { deliveryHareAPi, getSingleDataApi, userDeliveryAddressAPi } from '../utils/api'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaRegHandPointRight } from 'react-icons/fa'


const DeliveryAddress = () => {
    const pathname = window.location.href;
    const navigate = useNavigate();
    const userid = JSON.parse(localStorage.getItem('userdata')).id;
    const mobile = localStorage.getItem("usermobile");
    const [daddress, setDAddress] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (mobile) {
            getDeliveryAddressByUserNumber(mobile);
        }
        else {
            navigate("/login");
        }
    }, [mobile]);

    const {
        items,
        cartTotal,
    } = useCart();
    const cartdata = JSON.stringify(items);
    const [values, setUserValue] = useState({
        user_mobile: mobile,
        delivery_name: '',
        delivery_mobile: '',
        delivery_addresse: '',
        delivery_landmark: '',
        delivery_city: '',
        delivery_pincode: '',
        delivery_state: '',
        delivery_country: 'India',
        cart_data: cartdata,
        totalamt: cartTotal,
        userid: userid,
    });
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleDeliveryAddress = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                userDeliveryAddressAPi('add-delivery-address', values).then((res) => {
                    if (res.data.status === 1) {
                        localStorage.setItem("deliveryid", res.data.deliveryid);
                        localStorage.setItem("orderid", res.data.orderid);
                        navigate('/order-summary');
                    }
                })
            }
            catch (error) {
                toast.error("Server internal error!!");
            }
        }
        setValidated(true);
    }

    const getDeliveryAddressByUserNumber = (mobile) => {
        getSingleDataApi(`get-delivery-here-address/${mobile}`).then((res) => {
            if (res.data.status === 1) {
                setDAddress(...[res.data.address]);
            }
            else {
                setDAddress(false);
            }
        })
    }
    const handleRemoveAddress = (removeaddressid) => {
        getSingleDataApi(`remove-delivery-here-address/${removeaddressid}`).then((res) => {
            if (res.data.status === 1) {
                getDeliveryAddressByUserNumber(mobile);
                toast.success("Delivery address has been removed!")
            }
        })
    }
    const handleDeliveryHare = (deleiveryhereid) => {
        const datas = { 'deleiveryhereid': deleiveryhereid, 'cart_data': cartdata, 'userid': userid, 'totalamt': cartTotal };
        deliveryHareAPi("delivery-here-order", datas).then((res) => {
            if (res.data.status === 1) {
                localStorage.setItem("deliveryid", res.data.deliveryid);
                localStorage.setItem("orderid", res.data.orderid);
                navigate('/order-summary');
            }
        })
    }
    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Satkartar::Delivery Address</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="contentBody mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">delivery address</li>
                        </ol>
                    </nav>
                    <div className="steper">
                        <Row>
                            <div className="spetbar-card">
                                <Stepper activeStep={1}>
                                    <Step label="LOGIN" />
                                    <Step label="DELIVERY ADDRESS" />
                                    <Step label="ORDER SUMMARY" />
                                    <Step label="PAYMENT OPTIONS" />
                                </Stepper>
                            </div>
                        </Row>
                    </div>
                    <div className="contentBody">
                        {daddress != "" ?
                            <div className="account-card">
                                <div className="account-title">
                                    <h4>Delivery Address</h4>
                                </div>
                                {daddress?.map((daddress, index) => (
                                    <div className="account-contenrt ddeliveryBottom" key={index}>
                                        <div className="deliverynames">
                                            <div className="deliveryname">
                                                <b>{daddress?.delivery_name}</b>
                                                <b className="deliverynumber">{daddress?.delivery_mobile}</b>
                                            </div>
                                            <div className="deliveryBtn">
                                                {/* <button className="btnEdit">Edit</button> */}
                                                <button className="btnRemove" onClick={() => handleRemoveAddress(daddress?.id)}>Remove</button>
                                            </div>
                                        </div>
                                        <div className="adeliveryaddress">
                                            <span>{daddress?.delivery_addresse}, {daddress?.delivery_city}, {daddress?.delivery_state}, {daddress?.delivery_pincode}</span>
                                        </div>
                                        <div className="adeliveryaddressbtn mb-4">
                                            <button onClick={() => handleDeliveryHare(daddress?.id)}>DELIVER HERE</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            ""
                        }
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Add New Delivery Address</h4>
                            </div>
                            <div className="account-content">
                                <Form noValidate validated={validated} onSubmit={handleDeliveryAddress}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Name"
                                                name="delivery_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="mobile number"
                                                name="delivery_mobile"
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Full Address</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                type="text"
                                                placeholder="Delivery address"
                                                name="delivery_addresse"
                                                onChange={handleInputs}
                                                rows={1}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid delivery address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Landmark</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Landmark"
                                                name="delivery_landmark"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid landmark.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="City Name"
                                                name="delivery_city"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Pincode"
                                                name="delivery_pincode"
                                                pattern="[0-9]*"
                                                maxLength={6}
                                                minLength={6}
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="State"
                                                name="delivery_state"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid state name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Country"
                                                name="delivery_country"
                                                onChange={handleInputs}
                                                value="India"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button className="btncheckcont" type="submit">Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default DeliveryAddress