import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { fetchDataFromApi } from '../../utils/api';
import { baseUrl } from '../../utils/baseUrl';

const Slider = () => {
    const [desktopslider, setDesktopSlider] = useState([]);
    const [mobileslider, setMobileSlider] = useState([]);

    useEffect(() => {
        getDesktopBanner();
        getMobileBanner();
    }, [])

    const getDesktopBanner = () => {
        fetchDataFromApi('hero-banner').then((resp) => {
            if (resp.status === 1) {
                setDesktopSlider(...[resp.data])
            }
        })
    }
    const getMobileBanner = () => {
        fetchDataFromApi('hero-banner-mobile').then((resp) => {
            if (resp.status === 1) {
                setMobileSlider(...[resp.data])
            }
        })
    }
    return (
        <>
            <div className="desktop">
                <Carousel data-bs-theme="dark">
                    {desktopslider?.map((desktopslider, index) => (
                        <Carousel.Item key={index}>
                            <img className="d-block w-100" src={baseUrl(`banner/${desktopslider?.banner_img}`)} alt="First slide" />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className="mobile">
                <Carousel data-bs-theme="dark">
                    {mobileslider?.map((mobileslider, index) => (
                        <Carousel.Item key={index}>
                            <img className="d-block w-100" src={baseUrl(`banner/${mobileslider?.banner_img}`)} alt="First slide" />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
}

export default Slider