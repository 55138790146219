import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../components/Header.'
import Skeleton from 'react-loading-skeleton'
import { fetchDataFromApi } from '../utils/api'
import { baseUrl } from '../utils/baseUrl'

const Ingredients = () => {
    const pathname = window.location.href;
    const [ingredient, setIngredient] = useState([]);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        getIngredient();
    }, []);

    const getIngredient = () => {
        setIsloading(true)
        fetchDataFromApi('all-ingredient').then((res) => {
            if (res.status === 1) {
                setIsloading(false)
                setIngredient(...[res.data]);
            }
            else {
                setIsloading(true)
            }
        })
    }

    return (
        <div className="themebackgroundcolor">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ingredients</title>
                <link rel="canonical" href={pathname} />
            </Helmet>
            <Header />
            <div className="ingredient-section mb-4">
                <Container>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumbs">
                            <li className="breadcrumb-item" to="/">
                                <Link to="/" role="button" tabIndex="0">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">ingredients</li>
                        </ol>
                    </nav>
                    <Row>
                        {isLoading ?
                            <>
                                {(() => {
                                    const arr = [];
                                    for (let i = 0; i < 8; i++) {
                                        arr.push(
                                            <Col md={3} sm={12}>
                                                <Card className="ingredient-body">
                                                    <Skeleton className="skeimg" />
                                                    <Card.Body>
                                                        <Card.Title><Skeleton /></Card.Title>
                                                        <Card.Text className="ingdesc">
                                                            <Skeleton />
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    }
                                    return arr;
                                })()}
                            </>
                            :
                            ingredient?.map((ingredient, index) => (
                                <Col md={3} sm={12} key={index}>
                                    <Card className="ingredient-body">
                                        <Card.Img variant="top" src={baseUrl(`ingredient/${ingredient?.ingredient_img}`)} />
                                        <Card.Body>
                                            <Card.Title>{ingredient?.ingredient_name}</Card.Title>
                                            <Card.Text className="ingdesc">
                                                <div dangerouslySetInnerHTML={{ __html: ingredient?.ingredient_desc }}></div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Ingredients